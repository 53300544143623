import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Writemessage3 from '../../Component/Writemessage3'

import dg from "../../asset/images/innovation/risk_management/data_governance.svg";
import hm from "../../asset/images/innovation/risk_management/helping_in_modeling.svg";
import lff from "../../asset/images/innovation/risk_management/looking_to_formalize.svg";
import rav from "../../asset/images/innovation/risk_management/recalibration_and_validation.svg";
import stm from "../../asset/images/innovation/risk_management/seeking_to_mitigate.svg";
import st from "../../asset/images/innovation/risk_management/stress_testing.svg";
import wtb from "../../asset/images/innovation/risk_management/wanting_to_benchmark .svg";


import SettingsIcon from '@material-ui/icons/Settings';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import LoyaltyIcon from '@material-ui/icons/Loyalty';

import { Helmet } from 'react-helmet';

import Riskmanagement from "../../asset/images/new_banner/risk_management_banner.webp"

export default function RiskManagement() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <h1>The Analysis That Augments Your Quality Of Decisions
                </h1>
                <title>Risk Management Solutions - Decimal Point Analytics</title>
                <meta name="description" content="We offer Risk Management Solutions that prevents or manage critical risks that businesses face, assessing & controlling threats in their  finances. Get In Touch" />
                <meta name="keywords" content="credit risk modelling
CRM Support services provider
dashboard company" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/risk-management-company-in-us" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            {/* <Box className='mainsection' component='section'>
                <Container maxWidth='lg'>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item lg={5} md={6} sm={6} xs={12}>
                            <Box>
                                <Typography variant='h1' className='blue'>
                                    Risk Management
                                </Typography>
                                <Typography variant='h4' className='black'>
                                    The analysis that augments your quality of decisions
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item lg={7} md={6} sm={6} xs={12}>
                            <Box className='al_right'>
                                <img src={indexbanner} className='topbanner' alt='indexbanner' />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box> */}

            <Box className="banner_bx">
                <Box style={{ backgroundImage: "url(" + Riskmanagement + ")" }} className="banner_img">
                    <Box className="img_over bl_overlay">
                        <Grid container spacing={0} alignItems="center" justifyContent='center'>
                            <Grid item lg={3} md={4} sm={6} xs={10}>
                                <Box className="al_center">
                                    <Typography className="white" variant="h1">Risk Management</Typography>
                                    <Box mt={'10px'}>
                                        <Typography className="white" variant="h4">The Analysis That Augments Your Quality Of Decisions</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>

            <Box className='section2 sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    {/* <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography variant='h2' className='black'>
                                    Risk Management
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid> */}

                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center synopsic_bx">
                                <Typography variant='h6' className='black jst' gutterBottom>
                                    Risk is often an afterthought for financial institutions during strategy development. However, events ranging from the 2008 financial crisis to the recent pandemic have changed this worldview. Top management of Banks, Asset Managers, and Insurance companies have realized that a comprehensive view of their risks is no longer a luxury but a necessity. It enables them to provide appropriate checks and balances within a holistic risk management framework to counter the potential for individuals to discount seemingly compartmentalized risks that are significant at an overall organizational level.
                                </Typography>
                                <Typography variant='h6' className='black jst'>
                                    Decimal Point Analytics’ (DPA) Risk Consulting works closely with client management to measure and monitor the risk. We also ensure that risk is integrated with the strategy-setting process to cater to ALM and Operational, Credit & Market risk. DPA works with Banks and FIs to design, implement, and sustain effective capabilities & controls to manage their most critical risks. Through risk management, we assist them to recognize exposures & plug leakages, and reduce their adverse impact, thereby maximizing profits. We help evaluate loss data, scorecards, and existing models for reliable monitoring and reporting. In addition, as a partner to the client team, we work to implement new processes successfully over time.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Writemessage3 pageVisited="Risk Management" />

            <Box className='section mob_card' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container spacing={3} alignItems="center" justifyContent='center'>

                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='al_center'>
                                    <Typography variant='h2' className='black'>
                                        Our services are for
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box mt={1} >
                                    <Typography variant='h6' className='black al_center' gutterBottom>
                                        Banks, Financial Institutions (Private Equity, Hedge Funds, Pension Funds, Family Offices, etc.); Investment Bankers, Insurance Companies:
                                    </Typography>
                                </Box>

                            </Grid>
                        </Grid>

                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Box className='boxbg'>
                                <Box className='al_center'>
                                    <img src={stm} className='allic' alt='IC1' />
                                </Box>
                                <Box>
                                    <Typography variant='h6' className='black al_center'>Seeking to mitigate the exposure clients face across the entire portfolio and maximize profit </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Box className='boxbg'>
                                <Box className='al_center'>
                                    <img src={wtb} className='allic' alt='IC1' />
                                </Box>
                                <Box>
                                    <Typography variant='h6' className='black al_center'> Wanting to benchmark and improve the existing risk management framework</Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Box className='boxbg'>
                                <Box className='al_center'>
                                    <img src={lff} className='allic' alt='IC1' />
                                </Box>
                                <Box>
                                    <Typography variant='h6' className='black al_center'> Looking to formalize its risk management efforts across the enterprise </Typography>
                                </Box>
                            </Box>
                        </Grid>

                    </Grid>
                </Container>
            </Box>



            <Box className='section sec_bg ourstrength' component='section'>
                <Box className='over'></Box>
                <Container maxWidth='lg' className='bdr' style={{ position: 'relative', }}>

                    <Box className='mob_card' mt={3}>
                        <Grid container spacing={1} alignItems="top">

                            <Grid container spacing={1} alignItems="top">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className='al_center'>
                                        <Typography variant='h2' className='black'>
                                            How do our services move toward risk management?
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box mt={1}>
                                        <Typography variant='h6' className='black jst' gutterBottom>
                                            Instilling a risk-based culture is crucial to realize the ongoing benefits your organization can derive from an operational, credit or investment perspective. DPA’s risk consulting works with you to enhance existing risk frameworks & processes and create customized solutions. DPA’s activities include:
                                        </Typography>
                                    </Box>

                                </Grid>
                            </Grid>

                            <Grid item lg={3} md={3} sm={6} xs={12}>
                                <Box className='str_bg mnhg3'>
                                    <Box mb={1}>
                                        <img src={dg} className='bxicon' alt='DS1' />
                                    </Box>
                                    <Box>
                                        <Box mt={1}>
                                            <Typography className='blue' variant='h4'>Data Governance</Typography>
                                        </Box>
                                        <Box mt={1}>
                                            <Typography variant='h6' className='black al_center'>
                                                Data governance and gap analysis
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={3} md={3} sm={6} xs={12}>
                                <Box className='str_bg mnhg3'>
                                    <Box mb={1}>
                                        <img src={rav} className='bxicon' alt='DS2' />
                                    </Box>
                                    <Box>
                                        <Box mt={1}>
                                            <Typography className='blue' variant='h4'>facility risk</Typography>
                                        </Box>
                                        <Box mt={1}>
                                            <Typography variant='h6' className='black al_center'>
                                                Recalibration and validation of Obligor and facility risk rating models along with other market instruments
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={3} md={3} sm={6} xs={12}>
                                <Box className='str_bg mnhg3'>
                                    <Box mb={1}>
                                        <img src={hm} className='bxicon' alt='DS3' />
                                    </Box>
                                    <Box>
                                        <Box mt={1}>
                                            <Typography className='blue' variant='h4'>Institution-Specific stress scenarios</Typography>
                                        </Box>
                                        <Box mt={1}>
                                            <Typography variant='h6' className='black al_center'>
                                                Helping in modeling institution-specific stress scenarios on liquidity, forex, and interest rate
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={3} md={3} sm={6} xs={12}>
                                <Box className='str_bg mnhg3'>
                                    <Box mb={1}>
                                        <img src={st} className='bxicon' alt='DS4' />
                                    </Box>
                                    <Box>
                                        <Box mt={1}>
                                            <Typography className='blue' variant='h4'>Stress Testing </Typography>
                                        </Box>
                                        <Box mt={1}>
                                            <Typography variant='h6' className='black al_center'>
                                                Stress testing in line with evolving regulatory requirements across developed and developing markets
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>

            </Box>


            <Box className="section  ourstrength" component="section">
                <Container maxWidth="lg" className="bdr">
                    <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12} data-aos="fade-up">
                            <Box className="al_center">
                                <Typography className="black" variant="h2">
                                    DPA’s strengths lie in
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box mt={3}>
                        <Grid container spacing={2} alignItems="top" justifyContent='center'>
                            <Grid item lg={3} md={6} sm={6} xs={6} data-aos="fade-up">
                                <Box className="str_bg">
                                    <Box className="numcircle" mb={2}>
                                        <SettingsIcon />
                                    </Box>
                                    <Typography className="black" variant="h4" style={{ textAlign: 'center' }}>
                                        Operational Risk
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item lg={3} md={6} sm={6} xs={6} data-aos="fade-up">
                                <Box className="str_bg">
                                    <Box className="numcircle" mb={2}>
                                        <TrendingUpIcon />
                                    </Box>
                                    <Typography className="black" variant="h4" style={{ textAlign: 'center' }}>
                                        Market Risk & ALM
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item lg={3} md={6} sm={6} xs={12} data-aos="fade-up">
                                <Box className="str_bg">
                                    <Box className="numcircle" mb={2}>
                                        <LoyaltyIcon />
                                    </Box>
                                    <Typography className="black" variant="h4" style={{ textAlign: 'center' }}>
                                        Credit Risk
                                    </Typography>
                                </Box>
                            </Grid>

                        </Grid>
                    </Box>
                </Container>
            </Box>



            <Box className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography variant='h2' className='black'>
                                    Why prefer DPA for your Risk consulting needs?
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box mt={4}>
                                <Typography variant='h6' className='black jst' gutterBottom>
                                    Creating cutting-edge solutions for more than 19 years, we are a name to reckon with among <strong> data analytics service companies </strong> servicing multiple clients across developed economies with a team size above 700+ members. From a sophisticated investor base to newer age risks, our streamlined methodology would help our clients to implement customized Risk Management solutions.
                                </Typography>

                                <Typography variant='h6' className='black jst' gutterBottom>
                                    Our technology capabilities help us to handle voluminous data in <strong> big data </strong> projects and adhere to data privacy laws irrespective of geographical boundaries. Having completed over 700 projects with very positive client outcomes, DPA is well poised to deliver to its potential in the risk management domain. Our <strong> financial data analytics </strong> services cover aspects like data management & warehousing, process automation, and asset management that secures you in this risk-regulated environment. In the context of client requirements, we have been a part of multiple <strong> cloud-based data transformation </strong> projects worldwide, which speaks of our technological capabilities.
                                </Typography>

                                <Typography variant='h6' className='black jst' gutterBottom>
                                    Click here to book your 30-minute free consultation now. <Link to="contact-us" className="link"> Let’s talk! </Link>
                                </Typography>

                            </Box>

                        </Grid>
                    </Grid>
                </Container>
            </Box>


        </>
    );
}
