import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Writemessage3 from '../../Component/Writemessage3'
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import banenr2 from "../../asset/images/animation/datasolutionmid.webp";

import ic1 from "../../asset/images/innovation/ds_ic_1.svg";
import ic2 from "../../asset/images/innovation/ds_ic_2.svg";
import ic3 from "../../asset/images/innovation/ds_ic_3.svg";
import ic4 from "../../asset/images/innovation/ds_ic_4.svg";

import ds1 from "../../asset/images/innovation/ds1.svg";
import ds2 from "../../asset/images/innovation/ds2.svg";
import ds3 from "../../asset/images/innovation/ds3.svg";
import ds4 from "../../asset/images/innovation/ds4.svg";

import { Helmet } from 'react-helmet';

import DataSolution from "../../asset/images/new_banner/data_solution_banner.webp"

export default function Datasolution() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);





    return (
        <>


            <Helmet>
                <meta charset="UTF-8" />
                <h1>Customized, Optimized And Enhanced Research Operations</h1>
                <title>Best Data Solution Company In US- Decimal Point Analytics</title>
                <meta name="description" content="Our Data Solution services helps financial institutions & businesses analyze & achieve deep insights with enhanced data. Get in touch for expert services." />
                <meta name="keywords" content="AI in data analytics
Data Automation company
Data Cleaning Support
Data Management company
Data operations outsourcing
Data processing company" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/data-solution-in-us" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />

            </Helmet>


            {/* <Box className='mainsection' component='section'>
                <Container maxWidth='lg'>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item lg={5} md={6} sm={6} xs={12}>
                            <Box>
                                <Typography variant='h1' className='blue'>
                                    Data Solution
                                </Typography>

                                <Typography variant='h4' className='black'>
                                    Customized, Optimized and Enhanced research operations
                                </Typography>

                            </Box>
                        </Grid>
                        <Grid item lg={7} md={6} sm={6} xs={12}>
                            <Box className='al_right'>
                                <img src={indexbanner} className='topbanner' alt='Index Banner' />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box> */}


            <Box className="banner_bx">
                <Box style={{ backgroundImage: "url(" + DataSolution + ")" }} className="banner_img">
                    <Box className="img_over bl_overlay">
                        <Grid container spacing={0} alignItems="center" justifyContent='center'>
                            <Grid item lg={3} md={4} sm={5} xs={8}>
                                <Box className="al_center">
                                    <Typography className="white" variant="h1">Data Solution</Typography>
                                    <Box mt={'10px'}>
                                        <Typography className="white " variant="h4">Customized, Optimized And Enhanced Research Operations</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>


            <Box className='section2 sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    {/* <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography variant='h2' className='black'>
                                    Data Solution
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid> */}


                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center synopsic_bx">
                                <Typography variant='h6' className='black jst'>
                                    Decimal Point Analytics offers a variety of services to broad spectrum of clients in financial services industry, including buy-side, sell-side, and data providers. Use of algorithms and technology takes productivity and efficacy of many of our services to levels usually unseen in traditional research setups.
                                </Typography>
                            </Box>

                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Writemessage3 pageVisited="Data Solution" />

            <Box className='section mob_card' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container spacing={3} alignItems="center">

                        <Grid item lg={3} md={3} sm={12} xs={12}>
                            <Grid container spacing={3} alignItems="center">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className='boxbg'>
                                        <Box className='al_center'>
                                            <img src={ic1} className='allic' alt='IC1' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h4' className='black al_center'>Master Data Management</Typography>
                                            <Typography variant='h6' className='black al_center'>Regular upkeep of master data across the world </Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className='boxbg'>
                                        <Box className='al_center'>
                                            <img src={ic2} className='allic' alt='IC2' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h4' className='black al_center'>Transaction Data Management</Typography>
                                            <Typography variant='h6' className='black al_center'>Hourly or Daily updates of global market and other data </Typography>
                                        </Box>
                                    </Box>
                                </Grid>


                            </Grid>

                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Box className='al_center'>
                                <img src={banenr2} className='topbanner2' alt='Banner2' />
                            </Box>
                        </Grid>

                        <Grid item lg={3} md={3} sm={12} xs={12}>
                            <Grid container spacing={3} alignItems="center">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className='boxbg'>
                                        <Box className='al_center'>
                                            <img src={ic3} className='allic' alt='IC3' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h4' className='black al_center'>Real-time Feed Development</Typography>
                                            <Typography variant='h6' className='black al_center'>Scrapping the web for your exacting needs </Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className='boxbg'>
                                        <Box className='al_center'>
                                            <img src={ic4} className='allic' alt='IC4' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h4' className='black al_center'>Unstructured Data Management</Typography>
                                            <Typography variant='h6' className='black al_center'>Data gleaned from text </Typography>
                                        </Box>
                                    </Box>
                                </Grid>


                            </Grid>
                        </Grid>

                    </Grid>
                </Container>
            </Box>



            <Box className='section whydecimal sec_bg ourstrength ' component='section'>
                <Box className='over'></Box>
                <Container maxWidth='lg' className='bdr' style={{ position: 'relative', }}>
                    <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography variant='h2' className='black'>
                                    Investment Managers
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box className='mob_card' mt={3}>
                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={3} md={3} sm={6} xs={12}>
                                <Box className='str_bg mnhg4'>
                                    <Box mb={2}>
                                        <img src={ds1} className='bxicon' alt='DS1' />
                                    </Box>
                                    <Box>
                                        <Box mt={2}>
                                            <Typography className='blue' variant='h4'>Data</Typography>
                                        </Box>
                                        <Box mt={2}>
                                            <Typography variant='h6' className='black al_left'>
                                                <ul>
                                                    <li>Access to specialized and unique data</li>
                                                    <li>Data Management and Updates</li>
                                                </ul>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={3} md={3} sm={6} xs={12}>
                                <Box className='str_bg mnhg4'>
                                    <Box mb={2}>
                                        <img src={ds2} className='bxicon' alt='DS2' />
                                    </Box>
                                    <Box>
                                        <Box mt={2}>
                                            <Typography className='blue' variant='h4'>Information</Typography>
                                        </Box>
                                        <Box mt={2}>
                                            <Typography variant='h6' className='black al_left'>
                                                <ul>
                                                    <li>Extraction of critical information</li>
                                                    <li>Aggregation</li>
                                                    <li>Summary</li>
                                                </ul>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={3} md={3} sm={6} xs={12}>
                                <Box className='str_bg mnhg4'>
                                    <Box mb={2}>
                                        <img src={ds3} className='bxicon' alt='DS3' />
                                    </Box>
                                    <Box>
                                        <Box mt={2}>
                                            <Typography className='blue' variant='h4'>Analysis</Typography>
                                        </Box>
                                        <Box mt={2}>
                                            <Typography variant='h6' className='black al_left'>
                                                <ul>
                                                    <li>Financial Modeling</li>
                                                    <li>Company / Industry notes</li>
                                                    <li>Macro economic Studies</li>
                                                </ul>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={3} md={3} sm={6} xs={12}>
                                <Box className='str_bg mnhg4'>
                                    <Box mb={2}>
                                        <img src={ds4} className='bxicon' alt='DS4' />
                                    </Box>
                                    <Box>
                                        <Box mt={2}>
                                            <Typography className='blue' variant='h4'>Technology</Typography>
                                        </Box>
                                        <Box mt={2}>
                                            <Typography variant='h6' className='black al_left'>
                                                <ul>
                                                    <li>Automating Research and Reporting</li>
                                                    <li>ML Credit Risk Models</li>
                                                    <li>Web scraping for gathering information</li>
                                                </ul>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>








                        </Grid>
                    </Box>

                </Container>

            </Box>



            <Box className='section whydecimal sec_bg ourstrength' component='section'>
                <Box className='over'></Box>
                <Container maxWidth='lg' className='bdr' style={{ position: 'relative', }}>
                    <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography variant='h2' className='black'>
                                    Financial Intermediaries
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box className='mob_card' mt={3}>
                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={3} md={3} sm={6} xs={12}>
                                <Box className='str_bg mnhg3'>
                                    <Box mb={2}>
                                        <img src={ds1} className='bxicon' alt='DS1' />
                                    </Box>
                                    <Box>
                                        <Box mt={2}>
                                            <Typography className='blue' variant='h4'>Data</Typography>
                                        </Box>
                                        <Box mt={2}>
                                            <Typography variant='h6' className='black al_left'>
                                                <ul>
                                                    <li>	Building proprietary data sets	</li>
                                                    <li>	Events / Indicators	</li>
                                                </ul>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={3} md={3} sm={6} xs={12}>
                                <Box className='str_bg mnhg3'>
                                    <Box mb={2}>
                                        <img src={ds2} className='bxicon' alt='DS2' />
                                    </Box>
                                    <Box>
                                        <Box mt={2}>
                                            <Typography className='blue' variant='h4'>Information</Typography>
                                        </Box>
                                        <Box mt={2}>
                                            <Typography variant='h6' className='black al_left'>
                                                <ul>
                                                    <li>	Daily Market Bulletins	</li>
                                                    <li>	Weekly Market Bulletins	</li>

                                                </ul>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={3} md={3} sm={6} xs={12}>
                                <Box className='str_bg mnhg3'>
                                    <Box mb={2}>
                                        <img src={ds3} className='bxicon' alt='DS3' />
                                    </Box>
                                    <Box>
                                        <Box mt={2}>
                                            <Typography className='blue' variant='h4'>Analysis</Typography>
                                        </Box>
                                        <Box mt={2}>
                                            <Typography variant='h6' className='black al_left'>
                                                <ul>
                                                    <li>	Market Commentary	</li>
                                                    <li>	Technical Analysis	</li>
                                                    <li>	Primary and Secondary Research	</li>

                                                </ul>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={3} md={3} sm={6} xs={12}>
                                <Box className='str_bg mnhg3'>
                                    <Box mb={2}>
                                        <img src={ds4} className='bxicon' alt='DS4' />
                                    </Box>
                                    <Box>
                                        <Box mt={2}>
                                            <Typography className='blue' variant='h4'>Technology</Typography>
                                        </Box>
                                        <Box mt={2}>
                                            <Typography variant='h6' className='black al_left'>
                                                <ul>
                                                    <li>	Business Intelligence and MIS	</li>
                                                    <li>	Real-Time News Tickers	</li>

                                                </ul>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                        </Grid>
                    </Box>
                </Container>

            </Box>


        </>
    );
}
