import React, { useEffect, } from "react";
import { AppBar, Container, Grid, Tabs, Tab, Typography, Box, Button, TextField, } from "@material-ui/core";
import PropTypes from "prop-types";
import Aos from "aos";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom/cjs/react-router-dom";
import "aos/dist/aos.css";
import whyCP from "../../asset/images/product/creditpulse/why_credit_pulse.webp" 
import { Autocomplete, ListItemButton } from "@mui/material";

const options = [
    { label: 'Investor Relations Solutions', to: '/investor-relations-solutions' },
    { label: 'RFP Management Solutions', to: '/request-for-proposals-solutions' },
    { label: 'Due Diligence Questionnaires', to: '/due-diligence-questionnaires-solutions' },
    { label: 'Consultant Database Management', to: '/consultant-database-management-solutions' },
    { label: 'Customer Relationship Management', to: '/customer-relationship-management-solutions' },
];


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box mt={5}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
}

export default function InvestorRelations() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        window.scrollTo(0, 0);
        setValue(newValue);
    };

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    const [value1, setValue1] = React.useState(options[0]);

    return (
        <>
            <Helmet>
                <title>Expert Investor Relations CRM and RFP Management</title>
                <meta name="description" content="Streamline investor communications with our top-notch CRM and RFP solutions, designed for fund managers' growth and success." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/investor-relations-solutions" />
            </Helmet>

            <Box id="investor_relations_tabs" className="maintabbedsection stiky" mb={5}>
                <Container maxWidth="lg" className="bdr">
                    <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <AppBar position="static" className="tabbedbar">
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="none"
                                    textColor="primary"
                                    variant="fullWidth"
                                    scrollButtons="off"
                                    aria-label="scrollable auto tabs example"
                                    centered
                                >
                                    <Tab label="Investor Relations Solutions" {...a11yProps(0)} component={Link} to="/investor-relations-solutions" />
                                    <Tab label="RFP Management Solutions" {...a11yProps(1)} component={Link} to="/request-for-proposals-solutions" />
                                    <Tab label="Due Diligence Questionnaires" {...a11yProps(2)} component={Link} to="/due-diligence-questionnaires-solutions" />
                                    <Tab label="Consultant Database Management" {...a11yProps(3)} component={Link} to="/consultant-database-management-solutions" />
                                    <Tab label="Customer Relationship Management" {...a11yProps(4)} component={Link} to="/customer-relationship-management-solutions" />
                                </Tabs>
                            </AppBar>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box id="investor_relations_tabs_mob" className="maintabbedsection stiky" mb={5}  >
                <Container maxWidth="lg" className="bdr">
                    <Box className="alltxfieldlg">
                        <Autocomplete
                            disablePortal
                            options={options}
                            value={value1}
                            onChange={(event, newValue1) => setValue1(newValue1)}
                            fullWidth
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => <TextField {...params} />}
                            renderOption={(props, option) => (
                                <Link to={option.to}>
                                    <ListItemButton {...props}>
                                        <Typography variant="h6" className="mb0 black fw5">{option.label}</Typography>
                                    </ListItemButton>
                                </Link>
                            )}
                        />
                    </Box>
                </Container>
            </Box>


            <TabPanel value={value} index={0}>
                <Box id="investor_relations">
                    <Box className='section sec_bg' component='section' mt={'60px'}>
                        <Container maxWidth='lg' className='bdr'>
                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center synopsic_bx">
                                        <Box className='al_center'>
                                            <Typography variant='h1' className='black'>
                                                Simplifying Investor Communication & Reporting <span className='lower'>for</span> Global Fund Managers
                                            </Typography>
                                        </Box>
                                        <Typography variant='h6' className='black jst' gutterBottom>
                                            In the competitive asset management landscape, effective investor communication and reporting are key to building trust, supporting informed decisions, and securing investments. Decimal Point Analytics offers a comprehensive Investor Relations Solution with seamless management of Due Diligence Questionnaires (DDQ), Consultant Database Management (CDB), Customer Relationship Management (CRM), and Request for Proposals (RFP). Our solutions empower fund managers to focus on strategic growth with accurate, transparent, and timely investor communication.
                                        </Typography>
                                        <Typography variant='h6' className='black jst' gutterBottom>
                                            <Link to="/contact-us#contact_form" className="link">Contact Us</Link> to explore our comprehensive Investor Relations Solution
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>

                    {/* <Box className="section2" component="section">
                        <Container maxWidth="lg" className="bdr">

                            <Box mb={2}>
                                <Typography variant="h2" className="black al_left fw6 al_center" gutterBottom>
                                    What Are DDQs, RFPs, Consultant Databases, <span className='lower'>and</span> CRM?
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant='h3' className='blue' gutterBottom>
                                    Consultant Databases
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    Consultant databases serve as the first point of contact for potential investors, like institutional investors, pension funds, and retirement systems. They provide essential information about fund managers, investment vehicles, and fund performance. Fund managers must regularly update these databases to maintain their visibility among investors and ensure relevant data is accessible at all times.
                                </Typography>

                                <Typography variant='h3' className='blue' gutterBottom>
                                    Request for Proposals (RFPs)
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    RFPs are comprehensive documents created by potential investors to gather detailed information about a fund's strategies, investment processes, team structure, and performance history. They often cover questions such as:
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    <ul>
                                        <li>
                                            <Typography variant='h6' className='black jst mb0'>
                                                What is the fund’s investment philosophy?
                                            </Typography>
                                        </li>

                                        <li>
                                            <Typography variant='h6' className='black jst mb0'>
                                                Can you provide the performance history of your key strategies?
                                            </Typography>
                                        </li>

                                        <li>
                                            <Typography variant='h6' className='black jst mb0'>
                                                What are your risk management processes?
                                            </Typography>
                                        </li>

                                        <li>
                                            <Typography variant='h6' className='black jst mb0'>
                                                Describe your fund’s compliance and governance framework.
                                            </Typography>
                                        </li>

                                    </ul>
                                </Typography>

                                <Typography variant='h3' className='blue' gutterBottom>
                                    Due Diligence Questionnaires (DDQs)
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    Similar to RFPs, DDQs are used during the screening and evaluation phase to assess the credibility, performance, and suitability of fund managers. They focus on operational, financial, and policy-level due diligence, often including questions such as:
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    <ul>
                                        <li>
                                            <Typography variant='h6' className='black jst mb0'>
                                                What is your firm’s background and history?
                                            </Typography>
                                        </li>

                                        <li>
                                            <Typography variant='h6' className='black jst mb0'>
                                                How do you measure and manage investment risk?
                                            </Typography>
                                        </li>

                                        <li>
                                            <Typography variant='h6' className='black jst mb0'>
                                                What is the composition of your investment team?
                                            </Typography>
                                        </li>

                                        <li>
                                            <Typography variant='h6' className='black jst mb0'>
                                                How do you ensure regulatory compliance?
                                            </Typography>
                                        </li>

                                    </ul>
                                </Typography>

                                <Typography variant='h3' className='blue' gutterBottom>
                                    Customer Relationship Management (CRM)
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    A robust CRM system is critical for managing investor pipelines and fostering stronger relationships. DPA’s CRM solutions focus on optimizing Salesforce to streamline investor interactions, improve data management, and automate workflows. Our services include:
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    <ul>
                                        <li>
                                            <Typography variant='h6' className='black jst'>
                                                <strong>Implementation & Integration: </strong> Establishing CRM architecture, integrating with external systems, and setting up PowerBI/Tableau dashboards for insights.
                                            </Typography>
                                        </li>

                                        <li>
                                            <Typography variant='h6' className='black jst'>
                                                <strong>Data Management: </strong>  Ensuring data consistency, bulk uploads, and setting up KPI reports for actionable insights.
                                            </Typography>
                                        </li>

                                        <li>
                                            <Typography variant='h6' className='black jst'>
                                                <strong>Process Automation: </strong>  Automating tasks and workflows, creating custom applications, and deploying macros for efficiency.
                                            </Typography>
                                        </li>

                                        <li>
                                            <Typography variant='h6' className='black jst'>
                                                <strong>Ongoing Maintenance: </strong>  Continuous support for updates, customizations, and automated alerts to keep investor interactions seamless.
                                            </Typography>
                                        </li>

                                    </ul>
                                </Typography>

                            </Box>

                        </Container>
                    </Box> */}

                    <Box className="section2 " component="section">
                        <Container maxWidth="lg" className="bdr">

                            <Box mb={2}>
                                <Typography variant="h2" className="black al_center fw6" gutterBottom>
                                    Comprehensive Services <span className='lower'>for</span> Every Stage <span className='lower'>of</span> Investor Relations
                                </Typography>
                            </Box>

                            <Grid container spacing={2} alignItems="center" justifyContent='center'>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box id="new_innovation_design" className="reportinfo">
                                        <Grid container spacing={2} alignItems="top" justifyContent="center">
                                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                                <Box className='reportinfo'>
                                                    <Box className="reportm_bg">
                                                        <Box pb={2}>
                                                            <Button variant="contained" className='ora_title_btn'>
                                                                Pre-Investment Phase
                                                            </Button>
                                                        </Box>

                                                        <Box>
                                                            <Typography variant='h6' className='black jst'>
                                                                <ul>
                                                                    <li>
                                                                        <Typography variant='h6' className='black jst'>
                                                                            <strong>Deal Pipeline Management: </strong>  Efficient management of the deal pipeline, covering sourcing, evaluation, and negotiation processes.
                                                                        </Typography>
                                                                    </li>

                                                                    <li>
                                                                        <Typography variant='h6' className='black jst'>
                                                                            <strong>DDQ & RFP Handling: </strong>  Support in responding to customized DDQs and RFPs, addressing investor queries with precision and speed.
                                                                        </Typography>
                                                                    </li>

                                                                    <li>
                                                                        <Typography variant='h6' className='black jst'>
                                                                            <strong>Consultant Database Updates: </strong>  Regular updates to consultant databases, ensuring fund visibility and accessibility to potential investors.
                                                                        </Typography>
                                                                    </li>

                                                                    <li>
                                                                        <Typography variant='h6' className='black jst'>
                                                                            <strong>Industry Research & Newsletters: </strong>  Insightful research reports and newsletters that keep investors informed and engaged.
                                                                        </Typography>
                                                                    </li>

                                                                    <li>
                                                                        <Typography variant='h6' className='black jst'>
                                                                            <strong>Marketing & Fund Launches:  </strong> Development of investor teasers, pitch documents, and marketing materials for new fund launches, enhancing investor outreach.
                                                                        </Typography>
                                                                    </li>

                                                                </ul>
                                                            </Typography>
                                                        </Box>

                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                                <Box className='reportinfo'>
                                                    <Box className="reportm_bg">
                                                        <Box pb={2}>
                                                            <Button variant="contained" className='ora_title_btn'>
                                                                Post-Investment Phase
                                                            </Button>
                                                        </Box>

                                                        <Box>
                                                            <Typography variant='h6' className='black jst'>
                                                                <ul>
                                                                    <li>
                                                                        <Typography variant='h6' className='black jst'>
                                                                            <strong>Regulatory Filings & Documentation: </strong>  Management of regulatory filings, transaction documentation, and investor reporting to ensure compliance.
                                                                        </Typography>
                                                                    </li>

                                                                    <li>
                                                                        <Typography variant='h6' className='black jst'>
                                                                            <strong>Financial & ESG Reporting: </strong>  Comprehensive portfolio reporting, covering portfolio holdings, risk analysis, and performance attribution, along with ESG reporting for sustainable investments.
                                                                        </Typography>
                                                                    </li>

                                                                    <li>
                                                                        <Typography variant='h6' className='black jst'>
                                                                            <strong>Customized Reporting & Dashboards: </strong>  Interactive dashboards tailored to specific investor needs, offering real-time insights into fund performance, ESG factors, and compliance metrics.
                                                                        </Typography>
                                                                    </li>

                                                                    <li>
                                                                        <Typography variant='h6' className='black jst'>
                                                                            <strong>White Label Services: </strong>  Tailored branding services to ensure seamless communication with investors while maintaining consistency in reporting style and format.
                                                                        </Typography>
                                                                    </li>

                                                                    <li>
                                                                        <Typography variant='h6' className='black jst'>
                                                                            <strong>Ongoing Communication: </strong>  Proactive communication to keep investors informed about fund performance, updates, and changes in market dynamics, fostering stronger relationships.
                                                                        </Typography>
                                                                    </li>

                                                                </ul>
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>


                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className='flx_center mob_center mt16'>
                                        <Typography variant='h6' className='black jst' gutterBottom>
                                            <Link to="/contact-us#contact_form" className="link">Connect with us</Link> to streamline your process
                                        </Typography>
                                    </Box>
                                </Grid>

                            </Grid>

                        </Container>
                    </Box>

                    <Box className="section2 sec_bg" component="section">
                        <Container maxWidth="lg" className="bdr">

                            <Box mb={2}>
                                <Typography variant="h2" className="black al_center fw6" gutterBottom>
                                    Why Choose Decimal Point Analytics <span className='lower'>for</span> Investor Relations Solutions?
                                </Typography>
                            </Box>

                            <Grid container spacing={0} alignItems="center" justifyContent='center'>

                                <Grid item lg={5} md={5} sm={6} xs={12}>
                                    <Box className='al_center'>
                                        <img src={whyCP} className='topbanner5' alt='Why Choose Decimal Point Analytics for Investor Relations Solutions?' />
                                    </Box>
                                </Grid>

                                <Grid item lg={7} md={7} sm={6} xs={12}>
                                    <Box>
                                        <Typography className="black" variant="h6">
                                            <ul>
                                                <li>
                                                    <Typography variant="h6" className="black jst" gutterbottom>
                                                        <strong> Expertise & Accuracy: </strong>   We bring years of experience in managing complex RFPs, DDQs, and CRM systems, ensuring consistent, accurate, and timely responses.
                                                    </Typography>
                                                </li>

                                                <li>
                                                    <Typography variant="h6" className="black jst" gutterbottom>
                                                        <strong> Automation-Driven Efficiency: </strong>  Our AI-enabled solutions reduce manual effort, increase turnaround speed, and enhance response accuracy.
                                                    </Typography>
                                                </li>

                                                <li>
                                                    <Typography variant="h6" className="black jst" gutterbottom>
                                                        <strong> Comprehensive Support: </strong>  From content library creation to consultant database and CRM management, we offer end-to-end solutions tailored to each client’s needs.
                                                    </Typography>
                                                </li>

                                                <li>
                                                    <Typography variant="h6" className="black jst" gutterbottom>
                                                        <strong> Improved Outcomes: </strong>  Our solutions not only reduce lead times but also improve investor engagement by maintaining accurate and up-to-date information across all platforms.
                                                    </Typography>
                                                </li>
                                            </ul>
                                        </Typography>
                                    </Box>
                                </Grid>



                            </Grid>
                        </Container>
                    </Box>

                    <Box className="section2  " component="section">
                        <Container maxWidth='lg' className='bdr'>
                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center synopsic_bx">
                                        <Box>
                                            <Typography variant="h3" className="black al_center fw6" gutterBottom>
                                                Partner <span className='lower'>with</span> DPA <span className='lower'>for</span> Enhanced Investor Relations
                                            </Typography>
                                        </Box>
                                        <Typography variant='h6' className='black jst' gutterBottom>
                                            Let DPA transform your investor relations processes with our comprehensive, efficient, and automated solutions. Whether it’s managing DDQs, RFPs, consultant databases, or CRM systems, our approach ensures faster, more accurate communication, leading to stronger investor relationships and better fundraising outcomes.
                                        </Typography>
                                        <Typography variant='h6' className='black jst' gutterBottom>
                                            Discover how our comprehensive solutions can help you achieve faster, more accurate investor engagement. <Link to="/contact-us#contact_form" className="link">Connect with our team</Link>  today!
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </Box>
            </TabPanel>

        </>
    );
}
