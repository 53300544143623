import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { Helmet } from 'react-helmet';

import CAT from "../../asset/images/new_banner/catastrophe_modeling_banner.webp"

import ProductCTAA from '../../Component/ProductCTAA';
import whyCP from "../../asset/images/product/creditpulse/why_credit_pulse.webp"

import story1 from "../../asset/images/innovation/cat/story/story1.png";
import story2 from "../../asset/images/innovation/cat/story/story2.png";
import story3 from "../../asset/images/innovation/cat/story/story3.png";
import story4 from "../../asset/images/innovation/cat/story/story4.png";

import solution1 from "../../asset/images/innovation/cat/solutions/solution1.png";
import solution2 from "../../asset/images/innovation/cat/solutions/solution2.png";
import solution3 from "../../asset/images/innovation/cat/solutions/solution3.png";
import solution4 from "../../asset/images/innovation/cat/solutions/solution4.png";

import Services from "../../asset/images/innovation/cat/services.webp";

import service1 from "../../asset/images/innovation/cat/service/service1.png";
import service2 from "../../asset/images/innovation/cat/service/service2.png";
import service3 from "../../asset/images/innovation/cat/service/service3.png";
import service4 from "../../asset/images/innovation/cat/service/service4.png";
import service5 from "../../asset/images/innovation/cat/service/service5.png";
import service6 from "../../asset/images/innovation/cat/service/service6.png";

import NS from "../../asset/images/insight/podcast_new/naimuddin_shaikh.webp";

import ForwardIcon from '@mui/icons-material/Forward';


export default function CatastropheModeling() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>  Outsource Catastrophe Modeling for Insurance - DPA  </title>
                <meta name="description" content=" Decimal Point Analytics offers CAT modeling solutions for Insurance. From risk assessment to underwriting, we offer innovative insights." />
                <meta name="keywords" content="Catastrophe Modeling, Risk management, Insurance analytics, Risk mapping, Underwriting support, Geocoding, Data cleansing,  Reinsurance, CAT Modeling services, Risk analysis, Operational efficiency, Strategic insights,  Risk mit   igation, Decision-making support , Collaboration" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/catastrophe-modeling-services" />
                <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box className="banner_bx">
                <Box style={{ backgroundImage: "url(" + CAT + ")" }} className="banner_img">
                    <Box className="img_over bl_overlay">
                        <Grid container spacing={0} alignItems="center" justifyContent='center'>
                            <Grid item lg={5} md={7} sm={10} xs={10}>
                                <Box className="al_center" mt={3}>
                                    <Typography className="white" variant="h1">Catastrophe Modeling Services </Typography>
                                    <Box mt={'10px'}>
                                        <Typography className="white" variant="h4">Empower Your Risk Strategy With Our Advanced Catastrophe Modeling Solutions
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>

            <Box id='sba_solutions' className='section2 sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center synopsic_bx">
                                <Box className='al_center'>
                                    <Typography variant='h2' className='black'>
                                        Catastrophe Modeling Services <span className='lower'> for</span>  Insurance Companies
                                    </Typography>
                                </Box>
                                <Typography variant='h6' className='black jst' gutterBottom>
                                    In a world where catastrophic events grow increasingly unpredictable, the necessity for insurance companies to accurately assess and manage these risks intensifies. At Decimal Point Analytics, we offer comprehensive catastrophe modeling services tailored specifically for insurance companies. Our catastrophe modeling service meticulously quantifies potential financial losses due to disasters, thereby enhancing risk management practices. This includes sharpening underwriting decisions and bolstering solvency in the wake of major catastrophes. Through the application of advanced analytical tools and leveraging the deep domain knowledge of our dedicated team, we guide insurance companies in navigating the challenges of catastrophe risk with unparalleled precision and insight.
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='flx_center mt16'>
                                <ProductCTAA allignment="flx_fs" cta_content="For Catastrophe Risk Assessment" cta_btn_name="Contact Us Today!" pageVisited="Catastrophe Modeling Services" />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box id='sba_solutions' className='section mob_card' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container spacing={2} alignItems="center" justifyContent='center'>

                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='al_center'>
                                    <Typography variant='h2' className='black'>
                                        Enhancing Insurance Management With Catastrophe Modeling Solutions
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Typography variant='h6' className='black al_center' gutterBottom>
                            Our comprehensive catastrophe modeling solutions cover catastrophe risk assessment, underwriting support, and insurance modeling tailored to your specific needs. With our expertise in catastrophe risk analytics and modeling for insurance, we help you make informed decisions to mitigate risks and enhance resilience.
                        </Typography>

                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box className='boxbg mnhg4'>
                                <Box className='al_center'>
                                    <img src={solution1} className='allic' alt='Accurate CAT Risk Mapping & Forecasting' />
                                </Box>
                                <Box>
                                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                                        <Grid item lg={8} md={12} sm={12} xs={12}>
                                            <Box className='cat_m'>
                                                <Typography variant='h3' className='blue al_center fw6'>
                                                    Accurate CAT Risk Mapping & Forecasting
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <Typography variant='h6' className='black al_center mb0'>
                                        Leverage our advanced analytics for precise CAT risk mapping, enabling effective risk prediction and management within the insurance industry.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box className='boxbg mnhg4'>
                                <Box className='al_center'>
                                    <img src={solution2} className='allic' alt='Assisting Insurance Underwriters' />
                                </Box>
                                <Box>
                                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                                        <Grid item lg={8} md={10} sm={10} xs={12}>
                                            <Box className='cat_m'>
                                                <Typography variant='h3' className='blue al_center fw6'>
                                                    Assisting Insurance Underwriters
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Typography variant='h6' className='black al_center mb0'>
                                        Offering focused analytical support to insurance underwriters for core risk evaluation, enhancing their decision-making.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box className='boxbg mnhg4'>
                                <Box className='al_center'>
                                    <img src={solution3} className='allic' alt='Deep Dive into Catastrophe Risk Parameters' />
                                </Box>
                                <Box>
                                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                                        <Grid item lg={10} md={12} sm={12} xs={12}>
                                            <Box className='cat_m'>
                                                <Typography variant='h3' className='blue al_center fw6'>
                                                    Deep Dive into Catastrophe Risk Parameters
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Typography variant='h6' className='black al_center mb0'>
                                        Analyze specific catastrophe risk parameters to gain comprehensive insights into customer profiles and risk factors.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box className='boxbg mnhg4'>
                                <Box className='al_center'>
                                    <img src={solution4} className='allic' alt='Identifying Key Risk Zones' />
                                </Box>
                                <Box>
                                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                                        <Grid item lg={8} md={9} sm={8} xs={12}>
                                            <Box className='cat_m'>
                                                <Typography variant='h3' className='blue al_center fw6'>
                                                    Identifying Key Risk Zones
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Typography variant='h6' className='black al_center mb0'>
                                        Develop detailed risk maps that accurately identify high-risk zones based on Geocoding, aiding in proactive risk management.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='flx_center mob_center mt16'>
                                <ProductCTAA allignment="flx_fs" cta_content="Optimize Your Risk Strategy Today" cta_btn_name="Contact Us" pageVisited="Catastrophe Modeling Services" />
                            </Box>
                        </Grid>

                    </Grid>
                </Container>
            </Box>

            <Box id='sba_solutions' className='section mob_card sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr' style={{ position: 'relative', }}>
                    <Box>
                        <Grid container spacing={1} alignItems="top">

                            <Grid container spacing={1} alignItems="top">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className='al_center'>
                                        <Typography variant='h2' className='black'>
                                            Comprehensive CAT Modeling Support Services <span className='lower'>for</span> Insurance Companies
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Typography variant='h6' className='black al_center' gutterBottom>
                                We offer comprehensive CAT modeling support services tailored specifically for insurance companies. Our services cover various crucial aspects for effective catastrophe risk management:
                            </Typography>

                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">

                                <Grid item lg={9} md={11} sm={12} xs={12}>
                                    <Box className="al_center" my={3}>
                                        <img src={Services} className="topbanner2" alt="CAT Modeling Services for Insurance Companies" />
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={3} alignItems="top">
                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className='boxbg mnhg3'>
                                        <Box className='al_center'>
                                            <img src={service1} className='allic' alt='Insurance Coverage Verification' />
                                        </Box>
                                        <Box className='cat_m'>
                                            <Typography variant='h3' className='blue al_center fw6'>
                                                Insurance Coverage Verification
                                            </Typography>
                                            <Typography variant='h6' className='black al_center mb0'>
                                                We offer accurate compilation and validation of insurance details to ensure the integrity of coverage data.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className='boxbg mnhg3'>
                                        <Box className='al_center'>
                                            <img src={service2} className='allic' alt='Data Precision and Readiness' />
                                        </Box>
                                        <Box className='cat_m'>
                                            <Typography variant='h3' className='blue al_center fw6'>
                                                Data Precision and Readiness
                                            </Typography>
                                            <Typography variant='h6' className='black al_center mb0'>
                                                Our streamlined data cleansing and geocoding processes ensure that the data is accurate, organized, and ready for in-depth analysis.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className='boxbg mnhg3'>
                                        <Box className='al_center'>
                                            <img src={service3} className='allic' alt='Exposure and Loss Data Management' />
                                        </Box>
                                        <Box className='cat_m'>
                                            <Typography variant='h3' className='blue al_center fw6'>
                                                Exposure and Loss Data Management
                                            </Typography>
                                            <Typography variant='h6' className='black al_center mb0'>
                                                We provide regular updates to exposure and loss data, maintaining its integrity and relevance for precise risk assessment.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className='boxbg mnhg3'>
                                        <Box className='al_center'>
                                            <img src={service4} className='allic' alt='COPE Data Management' />
                                        </Box>
                                        <Box className='cat_m'>
                                            <Typography variant='h3' className='blue al_center fw6'>
                                                COPE Data Management
                                            </Typography>
                                            <Typography variant='h6' className='black al_center mb0'>
                                                Our team ensures precise updates of COPE (Construction, Occupancy, Protection, and Exposure) information, which is vital for accurate risk assessments and underwriting decisions.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className='boxbg mnhg3'>
                                        <Box className='al_center'>
                                            <img src={service5} className='allic' alt='Policy Data Updates' />
                                        </Box>
                                        <Box className='cat_m'>
                                            <Typography variant='h3' className='blue al_center fw6'>
                                                Policy Data Updates
                                            </Typography>
                                            <Typography variant='h6' className='black al_center mb0'>
                                                We offer timely adjustments to policy details to reflect current coverage needs, ensuring that insurance policies remain up-to-date and aligned with the evolving risk landscape.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className='boxbg mnhg3'>
                                        <Box className='al_center'>
                                            <img src={service6} className='allic' alt='Reinsurance Reporting' />
                                        </Box>
                                        <Box className='cat_m'>
                                            <Typography variant='h3' className='blue al_center fw6'>
                                                Reinsurance Reporting
                                            </Typography>
                                            <Typography variant='h6' className='black al_center mb0'>
                                                Our services include efficient reinsurance processes and detailed data quality reporting, empowering insurance companies to make strategic decisions and optimize their reinsurance arrangements.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Box mt={3}>
                                <Typography variant='h6' className='black al_center' gutterBottom>
                                    Through our expertise in catastrophe modeling and risk management, we aim to assist insurance companies in navigating the complex challenges of catastrophic events with confidence and precision.
                                </Typography>
                            </Box>

                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='flx_center mt16'>
                                    <ProductCTAA allignment="flx_fs" cta_content="For CAT Modeling Support Services" cta_btn_name="Contact Us" pageVisited="Catastrophe Modeling Services" />
                                </Box>
                            </Grid>

                        </Grid>
                    </Box>
                </Container>
            </Box>

            <Box id='sba_solutions' className="section" component="section">
                <Container maxWidth="lg" className="bdr">
                    <Grid container spacing={0} alignItems="center" justifyContent='center'>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center">
                                <Typography className="black" variant="h2">
                                    Why Outsource CAT Modeling Services <span className='lower'>for</span> Insurance?
                                </Typography>
                            </Box>
                        </Grid>

                        <Typography variant='h6' className='black al_center' gutterBottom>
                            By outsourcing catastrophe modeling for insurance to Decimal Point Analytics, you can benefit from our established processes and robust operations, which serve as a backbone for your mission-critical tasks. We pride ourselves on being pioneers in insurance CAT modeling. We have gone beyond the traditional modeling approach and laid the foundation for CAT modeling in the future, which leverages data and analytics to predict loss and risk at speeds that can only help your business save time and focus.
                        </Typography>

                        <Grid item lg={5} md={5} sm={6} xs={12}>
                            <Box className='al_center'>
                                <img src={whyCP} className='topbanner4' alt='Why Outsource CAT Modeling Services for Insurance? ' />
                            </Box>
                        </Grid>

                        <Grid item lg={7} md={7} sm={6} xs={12}>
                            <Box>
                                <Typography className="blue" variant="h6">
                                    <ul>
                                        <li>
                                            <Typography variant="h6" className="black jst" gutterbottom>
                                                <strong> Skilled Resources:  </strong> Gain access to a dedicated team of CAT Modeling analysts when local expertise is scarce, ensuring comprehensive and precise catastrophe risk assessment.
                                            </Typography>
                                        </li>

                                        <li>
                                            <Typography variant="h6" className="black jst" gutterbottom>
                                                <strong>Risk Management: </strong> Receive expert assistance in risk mapping and management, empowering informed decision-making processes and enhancing overall risk management practices.
                                            </Typography>
                                        </li>

                                        <li>
                                            <Typography variant="h6" className="black jst" gutterbottom>
                                                <strong> Analytics for Reinsurance: </strong> Utilize advanced analytics for reinsurance pricing, portfolio optimization, and strategic insights, enabling insurance companies to optimize their reinsurance arrangements effectively.
                                            </Typography>
                                        </li>

                                        <li>
                                            <Typography variant="h6" className="black jst" gutterbottom>
                                                <strong>  Efficiency & Collaboration: </strong>   Leverage time zones for smooth collaboration and rapid turnaround on projects, ensuring efficient project management and timely delivery of results.
                                            </Typography>
                                        </li>

                                        <li>
                                            <Typography variant="h6" className="black jst" gutterbottom>
                                                <strong> Stress Reduction: </strong>  Ramp up resources during peak periods to mitigate client stress and workload, ensuring consistent and reliable support even during challenging times.
                                            </Typography>
                                        </li>

                                        <li>
                                            <Typography variant="h6" className="black jst" gutterbottom>
                                                <strong> Seamless Transition:  </strong> Experience effortless process transitions with a team skilled in industry-leading catastrophe modeling software such as AIR Touchstone, RMS, SQL, and more.
                                            </Typography>
                                        </li>
                                    </ul>
                                </Typography>
                            </Box>

                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='flx_fs mob_center'>
                                    <ProductCTAA allignment="flx_fs" cta_content="Uncover the DPA Difference in ESG Management" cta_btn_name="Contact Us" pageVisited="Private Equity ESG Excellence Suite" />
                                </Box>
                            </Grid>

                        </Grid>


                    </Grid>
                </Container>
            </Box>

            <Box id='sba_solutions' className='section mob_card sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container spacing={2} alignItems="center" justifyContent='center'>

                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='al_center'>
                                    <Typography variant='h2' className='black'>
                                        CAT Modeling Transformation: A Success Story
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Typography variant='h6' className='black al_center' gutterBottom>
                            When a leading UK insurer faced local talent shortages and risk management hurdles, Decimal Point Analytics delivered an end-to-end CAT Modeling solution, marked by:
                        </Typography>

                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box className='boxbg mnhg2'>
                                <Box className='al_center'>
                                    <img src={story1} className='allic' alt='Expert Analyst Deployment' />
                                </Box>
                                <Box>
                                    <Typography variant='h6' className='blue al_center fw6'>
                                        Expert Analyst Deployment
                                    </Typography>
                                    <Typography variant='h6' className='black al_center mb0'>
                                        Filling critical skill gaps with our specialized team.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box className='boxbg mnhg2'>
                                <Box className='al_center'>
                                    <img src={story2} className='allic' alt='Enhanced Risk Analysis' />
                                </Box>
                                <Box>
                                    <Typography variant='h6' className='blue al_center fw6'>
                                        Enhanced Risk Analysis
                                    </Typography>
                                    <Typography variant='h6' className='black al_center mb0'>
                                        Upgrading their capabilities to map and manage risks efficiently.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box className='boxbg mnhg2'>
                                <Box className='al_center'>
                                    <img src={story3} className='allic' alt='Peak Demand Support' />
                                </Box>
                                <Box>
                                    <Typography variant='h6' className='blue al_center fw6'>
                                        Peak Demand Support
                                    </Typography>
                                    <Typography variant='h6' className='black al_center mb0'>
                                        Customizing analytics for stress-free operations during high-stakes periods.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box className='boxbg mnhg2'>
                                <Box className='al_center'>
                                    <img src={story4} className='allic' alt='Seamless Process Integration' />
                                </Box>
                                <Box>
                                    <Typography variant='h6' className='blue al_center fw6'>
                                        Seamless Process Integration
                                    </Typography>
                                    <Typography variant='h6' className='black al_center mb0'>
                                        Facilitating a smooth transition with our proficiency in advanced analytical tools.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Box mt={2}>
                            <Typography variant='h6' className='black al_center' gutterBottom >
                                Our collaboration not only solved their immediate challenges but also empowered the insurer with a strategic advantage, significantly boosting their operational efficiency, risk management precision, and market positioning.
                            </Typography>
                        </Box>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='flx_center mt16'>
                                <ProductCTAA allignment="flx_fs" cta_content="Uncover the Impact of Our Expertise Today!" cta_btn_name="Contact Us" pageVisited="Catastrophe Modeling Services" />
                            </Box>
                        </Grid>

                    </Grid>
                </Container>
            </Box>

            <Box id='sba_solutions' className="py3" component="section">
                <Container maxWidth="lg" className="bdr">
                    <Grid container spacing={0} alignItems="center" justifyContent='flex-start'>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="synopsic_bx">
                                <Box className='al_center'>
                                    <Typography variant='h2' className='black'>
                                        Unlock Unparalleled Success <span className='lower'> with</span> The DPA Advantage
                                    </Typography>
                                </Box>
                                <Typography variant='h6' className='black'>
                                    At Decimal Point Analytics, we transcend traditional boundaries to deliver unmatched value through our bespoke catastrophe modeling services and solutions. Our commitment to innovation drives market distinction, paving the way for novel business opportunities. Operational efficiency lies at our core, ensuring time and cost savings that empower your business agility in insurance risk modeling and catastrophic event modeling. With unparalleled expertise and a dedication to quality, we bring profound insights and robust solutions to every challenge in catastrophe risk analytics and modeling for insurance. Our dedicated support teams, comprised of experienced insurance catastrophe modelers, are at the heart of our client-centric approach, providing consistent, customized assistance to navigate the complexities of your industry.
                                </Typography>
                                <Typography variant='h6' className='black'>
                                    Partner with Decimal Point Analytics, the leading catastrophe modeling service provider, and experience a partnership that redefines success. Let us empower your journey with our cutting-edge catastrophe modeling services, strategic insights, and unwavering support in catastrophe underwriting services and reinsurance reporting.
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='flx_center mt16'>
                                <ProductCTAA allignment="flx_fs" cta_content="Explore the DPA Difference" cta_btn_name="Contact us today!" pageVisited="Catastrophe Modeling Services" />
                            </Box>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center" mt={5}>
                                <Typography className="blue h2v2 fw5" variant="h2">
                                    Podcast
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid container spacing={2} alignItems="center" justifyContent='center'>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='reportinfo'>
                                    <a href='https://www.youtube.com/watch?v=W8BGJ0TkPTc' target='_blank' rel="noopener noreferrer">
                                        <Box className="reportm_bg">
                                            <Box
                                                className="reportimg_bg"
                                                style={{
                                                    backgroundImage: 'url(' + NS + ')',
                                                }}
                                            >
                                            </Box>

                                            <Box className="reporttitle_box">
                                                <Typography className="reporttitle two">
                                                    Integrating CAT Modelling into Comprehensive Risk Management
                                                </Typography>
                                            </Box>
                                            <Typography variant='h6' className='skblue'>
                                                Jul 08, 2024
                                            </Typography>
                                            <Box className="line"></Box>
                                            <Box className="reporttitle_box">
                                                <Typography variant="h6" className="black jst thr">
                                                    Naimuddhin discusses CAT modeling's role in risk management, technological advancements, and innovative industry solutions
                                                </Typography>
                                            </Box>
                                            <Box className='flx_fs al_left'>
                                                <Box>
                                                    <Typography variant='h6' className='skblue lh20' gutterBottom>
                                                        Watch Now
                                                    </Typography>
                                                </Box>
                                                <Box mr={3}>
                                                    <ForwardIcon className='skblue fast_forward' fontSize='small' />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </a>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
}
