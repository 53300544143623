  
import case65 from "../../asset/images/insight/case_studies/case_studies_thumbnails/real_estate_finance.webp"
import case66 from "../../asset/images/insight/case_studies/case_studies_thumbnails/how_to_prevent_fraud.webp"
import case67 from "../../asset/images/insight/case_studies/case_studies_thumbnails/enhancing_sba_loan.webp"
import case68 from "../../asset/images/insight/case_studies/case_studies_thumbnails/enhancing_workflow_efficiency.webp"
import case69 from "../../asset/images/insight/case_studies/case_studies_thumbnails/cost_effective_excellence.webp"
import case70 from "../../asset/images/insight/case_studies/case_studies_thumbnails/doubling_productivity.webp"
import case71 from "../../asset/images/insight/case_studies/case_studies_thumbnails/saving_overheads.webp"
import case72 from "../../asset/images/insight/case_studies/case_studies_thumbnails/boosting_sba.webp"
import case73 from "../../asset/images/insight/case_studies/case_studies_thumbnails/reducing_penalty.png"
import case74 from "../../asset/images/insight/case_studies/case_studies_thumbnails/audit_radiness.webp"
import case75 from "../../asset/images/insight/case_studies/case_studies_thumbnails/improving_customer.webp"
import case76 from "../../asset/images/insight/case_studies/case_studies_thumbnails/achieving_faster_approvals.webp"
import case77 from "../../asset/images/insight/case_studies/case_studies_thumbnails/preventing_fraud.webp"

const SBA_Success_Stories = [
    {
        link: '/case-studies/preventing-5-million-in-fraud-and-increasing-efficiency-by-40-with-compliance-excellence',
        imgsrc: case77,
        heading: "Preventing $5 Million in Fraud and Increasing Efficiency by 40% with Compliance Excellence ",
        subheading: "High rejection rates, compliance gaps, and increased exposure to fraud posed serious risks. Proactive fraud detection measures, an adaptive compliance checklist, and meticulous due diligence processes led to the prevention of $5 million in fraud, a 40% increase in operational efficiency, and reduced loan rejections, strengthening compliance and financial integrity."
    },
    {
        link: '/case-studies/achieving-40-faster-approvals-and-doubling-application-throughout',
        imgsrc: case76,
        heading: "Achieving 40% Faster Approvals and Doubling Application Throughout",
        subheading: "Underwriting delays and scalability limitations affected processing speed and client satisfaction. Advanced workflows, optimized loan processing, and refined underwriting strategies led to 40% faster approvals, doubled application throughput, and maintained 99% compliance accuracy, enabling scalable growth and stronger competitiveness."
    },
    {
        link: '/case-studies/improving-customer-acquisition-by-25-faster-screening-and-40-increased-efficiency',
        imgsrc: case75,
        heading: "Improving Customer Acquisition by 25% Faster Screening and 40% Increased Efficiency",
        subheading: "Delays in loan screening slowed decision-making and customer acquisition, limiting competitiveness in the market. By optimizing the screening process, leveraging time-zone advantages, and implementing advanced tools, decision times decreased by 25%, operational efficiency increased by 40%, and customer acquisition accelerated, driving growth and improved market positioning."
    },
    {
        link: '/case-studies/achieving-100-backlog-clearance-in-less-than-2-months-for-sba-audit-readiness',
        imgsrc: case74,
        heading: "Achieving 100% Backlog Clearance in Less Than 2 Months for SBA Audit Readiness",
        subheading: "An urgent need to clear a substantial backlog of exceptions before an impending SBA audit posed compliance risks and strained resources. Rapid team scaling, improved exception handling, and efficient backlog management led to full backlog clearance in under two months. This proactive approach ensured audit readiness, compliance, and a restored focus on strategic growth initiatives."
    },
    {
        link: '/case-studies/reducing-penalty-payments-by-80-90-with-efficient-sba-reporting',
        imgsrc: case73,
        heading: "Reducing Penalty Payments by 80-90% with Efficient SBA Reporting",
        subheading: "Missed SBA deadlines and inefficient processes led to significant late payment penalties, diverting valuable resources away from core operations. By identifying bottlenecks, streamlining workflows, and focusing on compliance tasks, the finance firm achieved an 80-90% reduction in penalties, consistently met reporting deadlines, and improved operational efficiency, allowing resources to be redirected to growth initiatives."
    },
    {
        link: '/case-studies/boosting-sba-loan-approvals-by-30-with-99-documentation-accuracy',
        imgsrc: case72,
        heading: "Boosting SBA Loan Approvals by 30% with 99% Documentation Accuracy",
        subheading: "Frequent documentation errors, compliance gaps, and high rejection rates were impacting SBA loan approvals. A comprehensive documentation checklist, robust quality control measures, and proactive compliance tracking were implemented, resulting in 99% submission accuracy, a 30% reduction in rejections, and faster loan approvals, significantly improving compliance and approval rates."
    },
    {
        link: '/case-studies/saving-60-in-overheads-while-enhancing-sba-loan-quality-and-speed-by-40',
        imgsrc: case71,
        heading: "Saving 60% in Overheads While Enhancing SBA Loan Quality and Speed by 40%",
        subheading: "Escalating costs and compliance challenges demanded a strategic shift for a multi-strategy finance firm relying on onshore underwriting. Balancing costs while maintaining processing accuracy was vital. By adopting cost-effective outsourcing, loan automation, and refined quality measures, overheads were reduced by 60%, accuracy reached 99%, and turnaround times improved by 40%, driving both financial efficiency and growth."
    },
    {
        link: '/case-studies/doubling-productivity-achieving-2x-faster-loan-processing-with-50-fewer-errors',
        imgsrc: case70,
        heading: "Doubling Productivity Achieving 2x Faster Loan Processing with 50% Fewer Errors",
        subheading: "Operational bottlenecks, slow loan processing, and inconsistent quality control were key challenges for a multi-strategy real estate finance company. These issues impacted client satisfaction and market competitiveness. Through the adoption of automated loan underwriting, loan processing outsourcing, and loan due diligence, enhanced quality control measures, and scalable operations, productivity doubled, processing speed improved by 40%, and error rates dropped by 50%, resulting in a more efficient and consistent lending environment."
    },
    {
        link: '/case-studies/cost-effective-excellence–saving-lenders-60-in-overheads-while-enhancing-sba-loan-quality',
        imgsrc: case69,
        heading: "Cost-Effective Excellence – Saving Lenders 60% in Overheads While Enhancing SBA Loan Quality",
        subheading: "Our client is a multi-strategy real estate finance company that originates, acquires, finances, and services SBA loans for small to medium-sized businesses. Their goal is to empower businesses nationwide with fast, flexible funding solutions to support growth."
    },
    {
        link: '/case-studies/enhancing-workflow-efficiency-and-reducing-costs-with-precision',
        imgsrc: case68,
        heading: "Enhancing Workflow Efficiency and Reducing Costs with Precision",
        subheading: "Our client, a renowned real estate finance company specializing in SBA loans, struggled with timely and accurate SBA reporting. This led to frequent late payment penalties, inefficient resource allocation, and increased operational costs."
    },
    {
        link: '/case-studies/enhancing-sba-loan-approval-rates-through-precise-documentation-and-ensuring-sba-compliance',
        imgsrc: case67,
        heading: "Enhancing SBA Loan Approval Rates Through Precise Documentation and Ensuring SBA Compliance",
        subheading: "In the competitive landscape of real estate finance, maintaining compliance and preventing fraud is paramount. Our client, a leading real estate finance company, specializes in originating, acquiring, financing, and servicing small to medium-sized SBA loans. They are dedicated to empowering businesses nationwide with fast, flexible funding solutions that fuel growth."
    },
    {
        link: '/case-studies/how-to-prevent-fraud-in-real-eEstate-finance-a-case-study-on-compliance-excellence',
        imgsrc: case66,
        heading: "How to Prevent Fraud in Real Estate Finance: A Case Study on Compliance Excellence",
        subheading: "In the competitive landscape of real estate finance, maintaining compliance and preventing fraud is paramount. Our client, a leading real estate finance company, specializes in originating, acquiring, financing, and servicing small to medium-sized SBA loans. They are dedicated to empowering businesses nationwide with fast, flexible funding solutions that fuel growth."
    },
    {
        link: '/case-studies/how-automation-doubled-loan-processing-speed-and-cut-errors-by-50-for-real-estate-finance-firms',
        imgsrc: case65,
        heading: "How Automation Doubled Loan Processing Speed and Cut Errors by 50% for Real Estate Finance Firms",
        subheading: "A multi-strategy real estate finance company faced significant challenges, including operational bottlenecks, slow loan processing times, and inconsistent quality control. These issues were limiting client satisfaction and market competitiveness. By implementing advanced automation, stringent quality control measures, and scalable processes, the company achieved a 2x boost in productivity, a 40% improvement in processing speed, and a 50% reduction in error rates—creating a faster, more reliable lending environment."
    },
]

export default SBA_Success_Stories;