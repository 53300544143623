import React, { useEffect, } from "react";
import { AppBar, Container, Grid, Tabs, Tab, Typography, Box, TextField,  } from "@material-ui/core";
import PropTypes from "prop-types";
import Aos from "aos";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom/cjs/react-router-dom";
import "aos/dist/aos.css";
import whyCP from "../../../asset/images/product/creditpulse/why_credit_pulse.webp"
import Infographics from "../../../asset/images/services/investor_relations/role_of_rfp.webp"
import service1 from "../../../asset/images/services/investor_relations/rfp_services/service1.png";
import service2 from "../../../asset/images/services/investor_relations/rfp_services/service2.png";
import service3 from "../../../asset/images/services/investor_relations/rfp_services/service3.png";
import service4 from "../../../asset/images/services/investor_relations/rfp_services/service4.png";
import service5 from "../../../asset/images/services/investor_relations/rfp_services/service5.png";
import { Autocomplete, ListItemButton } from "@mui/material";

const options = [
    { label: 'Investor Relations Solutions', to: '/investor-relations-solutions' },
    { label: 'RFP Management Solutions', to: '/request-for-proposals-solutions' },
    { label: 'Due Diligence Questionnaires', to: '/due-diligence-questionnaires-solutions' },
    { label: 'Consultant Database Management', to: '/consultant-database-management-solutions' },
    { label: 'Customer Relationship Management', to: '/customer-relationship-management-solutions' },
];

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box mt={5}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
}

export default function RFP() {
    const [value, setValue] = React.useState(1);

    const handleChange = (event, newValue) => {
        window.scrollTo(0, 0);
        setValue(newValue);
    };

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    const [value1, setValue1] = React.useState(options[1]);

    return (
        <>
            <Helmet>
                <title>Efficient RFP Management for Fund Managers by DPA</title>
                <meta name="description" content="DPA's RFP management enhances speed, accuracy, and customization, enabling fund managers to respond effectively to investor inquiries and streamline investor engagement." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/request-for-proposals-solutions" />
            </Helmet>

            <Box id="investor_relations_tabs" className="maintabbedsection stiky" mb={5}>
                <Container maxWidth="lg" className="bdr">
                    <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <AppBar position="static" className="tabbedbar">
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="none"
                                    textColor="primary"
                                    variant="fullWidth"
                                    scrollButtons="off"
                                    aria-label="fullWidth auto tabs example"
                                    centered
                                >
                                    <Tab label="Investor Relations Solutions" {...a11yProps(0)} component={Link} to="/investor-relations-solutions" />
                                    <Tab label='RFP Management Solutions' {...a11yProps(1)} component={Link} to="/request-for-proposals-solutions" />
                                    <Tab label="Due Diligence Questionnaires" {...a11yProps(2)} component={Link} to="/due-diligence-questionnaires-solutions" />
                                    <Tab label="Consultant Database Management" {...a11yProps(3)} component={Link} to="/consultant-database-management-solutions" />
                                    <Tab label="Customer Relationship Management" {...a11yProps(4)} component={Link} to="/customer-relationship-management-solutions" />
                                </Tabs>
                            </AppBar>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box id="investor_relations_tabs_mob" className="maintabbedsection stiky" mb={5}  >
                <Container maxWidth="lg" className="bdr">
                    <Box className="alltxfieldlg">
                        <Autocomplete
                            disablePortal
                            options={options}
                            value={value1}
                            onChange={(event, newValue1) => setValue1(newValue1)}
                            fullWidth
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => <TextField {...params} />}
                            renderOption={(props, option) => (
                                <Link to={option.to}>
                                    <ListItemButton {...props}>
                                        <Typography variant="h6" className="mb0 black fw5">{option.label}</Typography>
                                    </ListItemButton>
                                </Link>
                            )}
                        />
                    </Box>
                </Container>
            </Box>

            <TabPanel value={value} index={1}>
                <Box id="investor_relations">
                    <Box className='section sec_bg' component='section' mt={'60px'}>
                        <Container maxWidth='lg' className='bdr'>
                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center synopsic_bx">
                                        <Box className='al_center'>
                                            <Typography variant='h1' className='black'>
                                                Request <span className='lower'>for</span> Proposals (RFP) Management 
                                            </Typography>
                                        </Box>
                                        <Typography variant='h6' className='black al_center' gutterBottom>
                                            Build investor confidence and expedite fundraising with efficient, accurate RFP management solutions.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>

                    <Box className="section2" component="section">
                        <Container maxWidth="lg" className="bdr">

                            <Box mb={2}>
                                <Typography variant="h2" className="black al_left fw6 al_center" gutterBottom>
                                    Streamlining RFP Management <span className='lower'>for</span> Precision, Speed, <span className='lower'>and</span> Consistency
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant='h6' className='black jst'>
                                    <strong>Request for Proposals (RFPs)</strong> are an essential component of the fundraising process for asset managers, providing investors with a detailed view of a fund’s strategies, processes, team, and historical performance. However, responding to RFPs can be time-intensive and complex, requiring a seamless blend of accuracy, customization, and speed. Decimal Point Analytics (DPA) offers an <strong>RFP management solution</strong> designed to enhance efficiency and accuracy, enabling fund managers to respond effectively to investor inquiries.
                                </Typography>
                            </Box>

                        </Container>
                    </Box>

                    <Box className="section2 sec_bg" component="section">
                        <Container maxWidth="lg" className="bdr">

                            <Box mb={2}>
                                <Typography variant="h2" className="black al_center fw6" gutterBottom>
                                    The Role <span className='lower'>of</span> RFPs <span className='lower'>in</span> Investor Engagement
                                </Typography>
                            </Box>

                            <Typography variant='h6' className='black al_center'>
                                RFPs serve as a structured document through which investors gather key insights and perform initial due diligence on fund managers. These documents help investors evaluate areas such as:
                            </Typography>

                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center" my={4}>
                                        <img src={Infographics} className="w100" alt="The Role of RFPs in Investor Engagement" />
                                    </Box>
                                </Grid>
                            </Grid>

                            <Typography variant='h6' className='black al_center'>
                                A well-structured <strong>RFP response</strong> can significantly impact an investor’s perception, often determining their interest in progressing further with a fund manager.
                            </Typography>

                        </Container>
                    </Box>

                    <Box className="section2 mob_card" component="section">
                        <Container maxWidth='lg' className='bdr'>
                            <Box mb={2}>
                                <Typography variant="h2" className="black fw6 al_center" gutterBottom>
                                    DPA’s Comprehensive RFP Services
                                </Typography>
                            </Box>

                            <Typography variant='h6' className='black al_center'>
                                At DPA, we support fund managers by providing an end-to-end <strong>RFP solution</strong> that covers every stage of response creation and submission. Our RFP services include:
                            </Typography>

                            <Grid container spacing={3} alignItems="center" justifyContent='center'>
                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className='boxbg mnhg3'>
                                        <Box className='al_center'>
                                            <img src={service1} className='allic' alt='Content Library Creation & Maintenance' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h6' className='blue al_center fw6'>
                                                Content Library Creation & Maintenance
                                            </Typography>
                                            <Typography variant='h6' className='black al_center mb0'>
                                                We maintain a central repository of past RFP responses, enabling quick retrieval and ensuring consistency across submissions.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className='boxbg mnhg3'>
                                        <Box className='al_center'>
                                            <img src={service2} className='allic' alt='First Draft Preparation' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h6' className='blue al_center fw6'>
                                                First Draft Preparation
                                            </Typography>
                                            <Typography variant='h6' className='black al_center mb0'>
                                                Using large language models (LLMs) and content libraries, we create a structured first draft that addresses typical investor questions and reduces lead time.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className='boxbg mnhg3'>
                                        <Box className='al_center'>
                                            <img src={service3} className='allic' alt='Customization & Precision' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h6' className='blue al_center fw6'>
                                                Customization & Precision
                                            </Typography>
                                            <Typography variant='h6' className='black al_center mb0'>
                                                Our team tailors each response to reflect the specific investor’s focus, highlighting the most relevant data, achievements, and compliance measures.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className='boxbg mnhg3'>
                                        <Box className='al_center'>
                                            <img src={service4} className='allic' alt='Regulatory & Compliance Assurance' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h6' className='blue al_center fw6'>
                                                Regulatory & Compliance Assurance
                                            </Typography>
                                            <Typography variant='h6' className='black al_center mb0'>
                                                DPA ensures that all RFP responses meet regulatory standards and accurately represent the fund’s operational practices.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className='boxbg mnhg3'>
                                        <Box className='al_center'>
                                            <img src={service5} className='allic' alt='Continuous Updates' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h6' className='blue al_center fw6'>
                                                Continuous Updates
                                            </Typography>
                                            <Typography variant='h6' className='black al_center mb0'>
                                                We regularly update responses with the latest performance metrics, governance changes, and relevant market insights, keeping submissions accurate and current.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>

                    <Box className="section2 sec_bg" component="section">
                        <Container maxWidth="lg" className="bdr">

                            <Box mb={2}>
                                <Typography variant="h2" className="black al_center fw6" gutterBottom>
                                    Why Choose DPA    <span className='lower'>for</span> RFP Management?
                                </Typography>
                            </Box>

                            <Grid container spacing={0} alignItems="center" justifyContent='center'>

                                <Grid item lg={5} md={5} sm={6} xs={12}>
                                    <Box className='al_center'>
                                        <img src={whyCP} className='topbanner5' alt='Why Choose DPA for RFP Management? ' />
                                    </Box>
                                </Grid>

                                <Grid item lg={7} md={7} sm={6} xs={12}>
                                    <Box>
                                        <Typography className="black" variant="h6">
                                            <ul>
                                                <li>
                                                    <Typography variant="h6" className="black jst" gutterbottom>
                                                        <strong> Time-Saving Efficiency: </strong>   With our AI-driven drafting process and extensive content library, DPA reduces the RFP response time by up to 70%, enabling fund managers to respond to investor requests promptly.
                                                    </Typography>
                                                </li>

                                                <li>
                                                    <Typography variant="h6" className="black jst" gutterbottom>
                                                        <strong>  Accurate & Consistent Responses: </strong>  Our team ensures that responses are both accurate and compliant, leveraging best practices to maintain high standards of data integrity.
                                                    </Typography>
                                                </li>

                                                <li>
                                                    <Typography variant="h6" className="black jst" gutterbottom>
                                                        <strong> Tailored to Investor Needs: </strong>  DPA customizes responses based on specific investor questions, creating a more engaging and relevant experience.
                                                    </Typography>
                                                </li>

                                                <li>
                                                    <Typography variant="h6" className="black jst" gutterbottom>
                                                        <strong> Advanced Automation: </strong> By integrating LLM technology, DPA offers a streamlined drafting process that minimizes manual intervention while maximizing response quality.
                                                    </Typography>
                                                </li>
                                            </ul>
                                        </Typography>
                                    </Box>
                                </Grid>



                            </Grid>
                        </Container>
                    </Box>

                    <Box className="section2" component="section">
                        <Container maxWidth='lg' className='bdr'>
                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center synopsic_bx">
                                        <Box>
                                            <Typography variant="h2" className="black al_center fw6" gutterBottom>
                                                Partner <span className='lower'>with</span> DPA <span className='lower'>for</span> Efficient RFP Management
                                            </Typography>
                                        </Box>
                                        <Typography variant='h6' className='black jst' gutterBottom>
                                            Our RFP solutions empower fund managers to communicate their value with precision and consistency. Let DPA streamline your RFP responses, ensuring you make a strong first impression on prospective investors. <Link to="/contact-us#contact_form" className="link">Contact us</Link> today!
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </Box>
            </TabPanel>

        </>
    );
}
