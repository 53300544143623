import React, { useEffect, } from "react";
import { AppBar, Container, Grid, Tabs, Tab, Typography, Box, TextField,  } from "@material-ui/core";
import PropTypes from "prop-types";
import Aos from "aos";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom/cjs/react-router-dom";
import "aos/dist/aos.css";
import whyCP from "../../../asset/images/product/creditpulse/why_credit_pulse.webp"

import solution1 from "../../../asset/images/innovation/crm_solutions/solutions/solution_1.png";
import solution2 from "../../../asset/images/innovation/crm_solutions/solutions/solution_2.png";
import solution3 from "../../../asset/images/innovation/crm_solutions/solutions/solution_3.png";
import solution4 from "../../../asset/images/innovation/crm_solutions/solutions/solution_4.png";
import solution5 from "../../../asset/images/innovation/crm_solutions/solutions/solution_5.png";

import CS1 from "../../../asset/images/insight/case_studies/case_studies_thumbnails/auto_and_custom.webp";
import CS2 from "../../../asset/images/insight/case_studies/case_studies_thumbnails/salesforce.webp";

import SB from "../../../asset/images/insight/podcast_new/suvidha_bhandary.webp"
import ForwardIcon from '@mui/icons-material/Forward';
import MakingCRM from "../../../asset/images/insight/blog_thumbnails/making_crm.webp"

import ProductCTAA from "../../../Component/ProductCTAA";
import { Autocomplete, ListItemButton } from "@mui/material";

const options = [
    { label: 'Investor Relations Solutions', to: '/investor-relations-solutions' },
    { label: 'RFP Management Solutions', to: '/request-for-proposals-solutions' },
    { label: 'Due Diligence Questionnaires', to: '/due-diligence-questionnaires-solutions' },
    { label: 'Consultant Database Management', to: '/consultant-database-management-solutions' },
    { label: 'Customer Relationship Management', to: '/customer-relationship-management-solutions' },
];

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box mt={5}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
}

export default function CRM() {
    const [value, setValue] = React.useState(4);

    const handleChange = (event, newValue) => {
        window.scrollTo(0, 0);
        setValue(newValue);
    };

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    const [value1, setValue1] = React.useState(options[4]);


    return (
        <>
            <Helmet>
                <title>Maximize Your Salesforce CRM Potential | DPA Salesforce Support Solutions</title>
                <meta name="description" content="Unlock the full potential of your Salesforce CRM with DPA's certified experts. Our tailored solutions optimize efficiency, streamline processes, and enhance client engagement." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/customer-relationship-management-solutions" />
            </Helmet>

            <Box id="investor_relations_tabs" className="maintabbedsection stiky" mb={5}>
                <Container maxWidth="lg" className="bdr">
                    <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <AppBar position="static" className="tabbedbar">
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="none"
                                    textColor="primary"
                                    variant="fullWidth"
                                    scrollButtons="off"
                                    aria-label="fullWidth auto tabs example"
                                    centered
                                >
                                    <Tab label="Investor Relations Solutions" {...a11yProps(0)} component={Link} to="/investor-relations-solutions" />
                                    <Tab label="RFP Management Solutions" {...a11yProps(1)} component={Link} to="/request-for-proposals-solutions" />
                                    <Tab label="Due Diligence Questionnaires" {...a11yProps(2)} component={Link} to="/due-diligence-questionnaires-solutions" />
                                    <Tab label="Consultant Database Management" {...a11yProps(3)} component={Link} to="/consultant-database-management-solutions" />
                                    <Tab label="Customer Relationship Management" {...a11yProps(4)} component={Link} to="/customer-relationship-management-solutions" />
                                </Tabs>
                            </AppBar>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box id="investor_relations_tabs_mob" className="maintabbedsection stiky" mb={5}  >
                <Container maxWidth="lg" className="bdr">
                    <Box className="alltxfieldlg">
                        <Autocomplete
                            disablePortal
                            options={options}
                            value={value1}
                            onChange={(event, newValue1) => setValue1(newValue1)}
                            fullWidth
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => <TextField {...params} />}
                            renderOption={(props, option) => (
                                <Link to={option.to}>
                                    <ListItemButton {...props}>
                                        <Typography variant="h6" className="mb0 black fw5">{option.label}</Typography>
                                    </ListItemButton>
                                </Link>
                            )}
                        />
                    </Box>
                </Container>
            </Box>

            <TabPanel value={value} index={4}>
                <Box id="investor_relations">
                    <Box className='section sec_bg' component='section' mt={'60px'}>
                        <Container maxWidth='lg' className='bdr'>
                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center synopsic_bx">
                                        <Box className='al_center'>
                                            <Typography variant='h1' className='black'>
                                                DPA's Salesforce CRM Support Solutions
                                            </Typography>
                                        </Box>
                                        <Typography variant='h6' className='black al_center' gutterBottom>
                                            Unlock the Full Potential of Your Salesforce CRM with DPA
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>

                    <Box className="section2" component="section">
                        <Container maxWidth="lg" className="bdr">

                            <Box mb={2}>
                                <Typography variant="h2" className="black al_left fw6 al_center" gutterBottom>
                                    Maximize Your Salesforce CRM Potential
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant='h6' className='black jst' gutterBottom>
                                    At Decimal Point Analytics, we understand the crucial role of a robust CRM system in managing business pipelines and client relationships. Our CRM Support Solutions are designed to help you optimize your business processes and foster deeper connections with your clients. With our expertise, we can assist you in selecting, implementing, and maintaining the most suitable CRM system for your specific needs, ensuring that your business operates efficiently and effectively.
                                </Typography>
                            </Box>

                        </Container>
                    </Box>

                    <Box className="section2 sec_bg mob_card new_cs" component="section">
                        <Container maxWidth='lg' className='bdr'>
                            <Grid container spacing={2} alignItems="center" justifyContent='center'>

                                <Grid container spacing={1} alignItems="top">
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box className='al_center'>
                                            <Typography variant='h2' className='black'>
                                                Comprehensive CRM Solutions
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className='boxbg mnhg5'>
                                        <Box className='al_center'>
                                            <img src={solution1} className='allic' alt='Implementation & Integration' />
                                        </Box>
                                        <Box>
                                            <Grid container spacing={1} alignItems="top" justifyContent='center'>
                                                <Grid item lg={12} md={9} sm={12} xs={12}>
                                                    <Box className='cat_m'>
                                                        <Typography variant='h3' className='blue al_center fw6'>
                                                            Implementation & Integration
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box>
                                                <Typography variant='h6' className='black al_left'>
                                                    <ul>
                                                        <li>Establish data architecture with defined hierarchies and integrate Salesforce with external databases.</li>
                                                        <li>Integrate existing data, email systems, and external APIs.</li>
                                                        <li>Customize and implement PowerBI & Tableau dashboards for enhanced data visualization.</li>
                                                    </ul>
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className='boxbg mnhg5'>
                                        <Box className='al_center'>
                                            <img src={solution2} className='allic' alt='Data Management' />
                                        </Box>
                                        <Box>
                                            <Grid container spacing={1} alignItems="top" justifyContent='center'>
                                                <Grid item lg={12} md={6} sm={12} xs={12}>
                                                    <Box className='cat_m'>
                                                        <Typography variant='h3' className='blue al_center fw6'>
                                                            Data Management
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box>
                                                <Typography variant='h6' className='black al_left'>
                                                    <ul>
                                                        <li>Standardize and eliminate duplicate data for consistency.</li>
                                                        <li>Perform bulk data uploads, updates, and maintain overall data quality.</li>
                                                        <li>Set up pipelines and KPI reports for actionable insights.</li>
                                                    </ul>
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className='boxbg mnhg5'>
                                        <Box className='al_center'>
                                            <img src={solution3} className='allic' alt='Process Automation' />
                                        </Box>
                                        <Box>
                                            <Grid container spacing={1} alignItems="top" justifyContent='center'>
                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <Box className='cat_m'>
                                                        <Typography variant='h3' className='blue al_center fw6'>
                                                            Process Automation
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box>
                                                <Typography variant='h6' className='black al_left'>
                                                    <ul>
                                                        <li>Design and deploy custom Salesforce applications.</li>
                                                        <li>Automate tasks using SOQL, APIs, and Flow Builder.</li>
                                                        <li>Create custom objects, formula fields, and macros to streamline processes.</li>
                                                    </ul>
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className='boxbg mnhg5'>
                                        <Box className='al_center'>
                                            <img src={solution4} className='allic' alt='Ongoing Maintenance Support' />
                                        </Box>
                                        <Box>
                                            <Grid container spacing={1} alignItems="top" justifyContent='center'>
                                                <Grid item lg={12} md={12} sm={9} xs={12}>
                                                    <Box className='cat_m'>
                                                        <Typography variant='h3' className='blue al_center fw6'>
                                                            Ongoing Maintenance Support
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box>
                                                <Typography variant='h6' className='black al_left'>
                                                    <ul>
                                                        <li>Manage change requests, custom objects, and triggers.</li>
                                                        <li>Offer continuous support for Salesforce updates and new features.</li>
                                                        <li>Implement automated alerts, updates, and synchronization with Outlook.</li>
                                                    </ul>
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className='boxbg mnhg5'>
                                        <Box className='al_center'>
                                            <img src={solution5} className='allic' alt='Customization & Value-added Services' />
                                        </Box>
                                        <Box>
                                            <Grid container spacing={1} alignItems="top" justifyContent='center'>
                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <Box className='cat_m'>
                                                        <Typography variant='h3' className='blue al_center fw6'>
                                                            Customization & Value-added Services
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box>
                                                <Typography variant='h6' className='black al_left'>
                                                    <ul>
                                                        <li>Customize user interfaces using Visualforce and Lightning Web Components.</li>
                                                        <li>Develop custom forms and input fields for seamless client interactions.</li>
                                                        <li>Build interactive dashboards and set automated meeting alerts for productivity.</li>
                                                    </ul>
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>

                            </Grid>
                        </Container>
                    </Box>

                    <Box className="section2 mob_card" component="section">
                        <Container maxWidth="lg" className="bdr">
                            <Grid container spacing={0} alignItems="center" justifyContent='center'>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center">
                                        <Typography className="black" variant="h2">
                                            Why Choose DPA <span className='lower'>for</span> Salesforce CRM?
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Box className='al_center'>
                                        <img src={whyCP} className='topbanner4' alt='Why Choose DPA for Salesforce CRM?' />
                                    </Box>
                                </Grid>

                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Box>
                                        <Typography className="blue" variant="h6">
                                            <ul>
                                                <li>
                                                    <Typography variant="h6" className="black jst" gutterbottom>
                                                        <strong>Certified Salesforce Experts: </strong> Our team is comprised of Salesforce Certified Administrators, Developers, and Consultants who bring deep domain expertise and a proven track record in Salesforce CRM solutions.
                                                    </Typography>
                                                </li>

                                                <li>
                                                    <Typography variant="h6" className="black jst" gutterbottom>
                                                        <strong>End-to-End Solutions: </strong> From setting up your Salesforce architecture to integrating external data and automating processes, we offer comprehensive services tailored to your unique business needs.
                                                    </Typography>
                                                </li>

                                                <li>
                                                    <Typography variant="h6" className="black jst" gutterbottom>
                                                        <strong>Innovation and Adaptability:  </strong> We stay ahead of the curve by keeping up with the latest Salesforce features, ensuring that your CRM system is always optimized for performance and innovation.
                                                    </Typography>
                                                </li>

                                                <li>
                                                    <Typography variant="h6" className="black jst" gutterbottom>
                                                        <strong>Seamless Integration: </strong> We specialize in integrating Salesforce with external applications like PowerBI, Tableau, and more, enabling comprehensive data visualization and informed decision-making.
                                                    </Typography>
                                                </li>
                                            </ul>
                                        </Typography>
                                    </Box>

                                </Grid>


                            </Grid>
                        </Container>
                    </Box>

                    <Box className="section2 sec_bg mob_card" component="section">
                        <Container maxWidth='lg' className='bdr' style={{ position: 'relative', }}>
                            <Box>
                                <Grid container spacing={1} alignItems="top">

                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box className="al_center">
                                            <Typography className="blue h2v2 fw5" variant="h2">
                                                Success Stories
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    <Grid container spacing={2} alignItems="center" justifyContent='center'>
                                        <Grid item lg={4} md={4} sm={6} xs={12}>
                                            <Box className='reportinfo'>
                                                <Link to='/case-studies/salesforce-automation-to-simplify-your-complex-processes'>
                                                    <Box className="reportm_bg">
                                                        <Box
                                                            className="reportimg_bg"
                                                            style={{
                                                                backgroundImage: 'url(' + CS1 + ')',
                                                            }}
                                                        >
                                                        </Box>

                                                        <Box className="reporttitle_box">
                                                            <Typography className="reporttitle two">
                                                                Streamlining Loan Processing for a US-based Listed Lending Company
                                                            </Typography>
                                                        </Box>
                                                        <Box className="line"></Box>
                                                        <Box className="reporttitle_box">
                                                            <Typography variant="h6" className="black jst">
                                                                A US-based lending company faced delays and errors due to manual data entry and a cluttered Salesforce interface. We addressed this by automating data processes, redesigning the interface with Visualforce, and migrating workflows to Salesforce flows. These changes resulted in faster loan approvals, reduced errors, and significant cost savings.
                                                            </Typography>
                                                        </Box>
                                                        <Box className='flx_fs al_left'>
                                                            <Typography variant='h6' className='blue' gutterBottom>
                                                                <strong>Learn more</strong>
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Link>
                                            </Box>
                                        </Grid>

                                        <Grid item lg={4} md={4} sm={6} xs={12}>
                                            <Box className='reportinfo'>
                                                <Link to='/case-studies/tailoring-process-automation-to-provide-quality-data-in-salesforce-crm'>
                                                    <Box className="reportm_bg">
                                                        <Box
                                                            className="reportimg_bg"
                                                            style={{
                                                                backgroundImage: 'url(' + CS2 + ')',
                                                            }}
                                                        >
                                                        </Box>

                                                        <Box className="reporttitle_box">
                                                            <Typography className="reporttitle two">
                                                                Advancing CRM Capabilities for a $41 Billion Investment Management Company
                                                            </Typography>
                                                        </Box>
                                                        <Box className="line"></Box>
                                                        <Box className="reporttitle_box">
                                                            <Typography variant="h6" className="black jst">
                                                                An asset management firm managing $41 billion in assets needed to enhance their CRM for better data quality and decision-making. We standardized and corrected database errors, built interactive dashboards for insights, and automated workflows to improve efficiency. These solutions provided stronger data insights, efficient operations, and improved client interactions.
                                                            </Typography>
                                                        </Box>
                                                        <Box className='flx_fs al_left'>
                                                            <Typography variant='h6' className='blue' gutterBottom>
                                                                <strong>Learn more</strong>
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Link>
                                            </Box>
                                        </Grid>

                                    </Grid>

                                </Grid>
                            </Box>
                        </Container >
                    </Box>

                    <Box className="section2" component="section">
                        <Container maxWidth='lg' className='bdr'>
                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center synopsic_bx">
                                        <Box className='al_center'>
                                            <Typography variant='h2' className='black'>
                                                Let’s Transform Your Salesforce Experience Together
                                            </Typography>

                                            <Typography variant='h6' className='black jst'>
                                                Partner with DPA to elevate your Salesforce CRM experience. Our Salesforce Certified Professionals are dedicated to transforming complex challenges into streamlined, effective solutions tailored to your unique needs. Contact us today for a 30-minute complimentary consultation and let us help you achieve Salesforce excellence.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center" mt={5}>
                                        <Typography className="blue h2v2 fw5" variant="h2">
                                            Podcast
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid container spacing={2} alignItems="center" justifyContent='center'>
                                    <Grid item lg={4} md={4} sm={6} xs={12}>
                                        <Box className='reportinfo'>
                                            <a href='https://www.youtube.com/watch?v=exWEn6MR36k' target='_blank' rel="noopener noreferrer">
                                                <Box className="reportm_bg">
                                                    <Box
                                                        className="reportimg_bg"
                                                        style={{
                                                            backgroundImage: 'url(' + SB + ')',
                                                        }}
                                                    >
                                                    </Box>

                                                    <Box className="reporttitle_box">
                                                        <Typography className="reporttitle two">
                                                            Leveraging Salesforce Automation for Financial Reporting & Analysis
                                                        </Typography>
                                                    </Box>
                                                    <Typography variant='h6' className='skblue'>
                                                        May 31, 2024
                                                    </Typography>
                                                    <Box className="line"></Box>
                                                    <Box className="reporttitle_box">
                                                        <Typography variant="h6" className="black jst thr">
                                                            Suvidha shares how Salesforce enhances client productivity with tailored solutions and expert integration.
                                                        </Typography>
                                                    </Box>
                                                    <Box className='flx_fs al_left'>
                                                        <Box>
                                                            <Typography variant='h6' className='skblue lh20' gutterBottom>
                                                                Watch Now
                                                            </Typography>
                                                        </Box>
                                                        <Box mr={3}>
                                                            <ForwardIcon className='skblue fast_forward' fontSize='small' />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </a>
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className='flx_center mt16'>
                                        <ProductCTAA allignment="flx_fs" cta_content="Let’s collaborate to streamline your CRM processes" cta_btn_name="Contact us" pageVisited="DPA's Salesforce CRM Support Solutions" />
                                    </Box>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center" mt={5}>
                                        <Typography className="blue h2v2 fw5" variant="h2">
                                            Blog
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid container spacing={2} alignItems="center" justifyContent='center'>
                                    <Grid item lg={4} md={4} sm={6} xs={12}>
                                        <Box className='reportinfo'>
                                            <Link to='/blog/making-crm-work-for-financial-services'>
                                                <Box className="reportm_bg">
                                                    <Box
                                                        className="reportimg_bg"
                                                        style={{
                                                            backgroundImage: 'url(' + MakingCRM + ')',
                                                        }}
                                                    >
                                                    </Box>

                                                    <Box className="reporttitle_box">
                                                        <Typography className="reporttitle two">
                                                            Making CRM work for financial services
                                                        </Typography>
                                                    </Box>
                                                    <Box className="line"></Box>
                                                </Box>
                                            </Link>
                                        </Box>
                                    </Grid>
                                </Grid>

                            </Grid>

                        </Container>
                    </Box>


                </Box>
            </TabPanel>

        </>
    );
}
