import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Casestudyback from '../../../../Component/Casestudyback'

import Banner from "../../../../asset/images/insight/case_studies/banner_automated_process.webp"
import Infographics from "../../../../asset/images/insight/case_studies/achieving_60_faster_processing.webp"
import { Link } from 'react-router-dom';



export default function Achieving60FasterProcessing() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet><meta charset="UTF-8" />
                <meta charset="UTF-8" />
                <title>"Bridge Lending Automation Cuts Processing Times by 60%"</title>
                <meta name="description" content="Discover how a US bridge lending firm enhanced efficiency with automated processes reducing processing times by 60%" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/achieving-60-faster-processing-times-in-bridge-lending-with-automated-processes" />
            </Helmet>

            <Box id="case-studies" className='section sec_bg new_cs' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography className='mainhead blue al_center'>
                                Achieving 60% Faster Processing Times <span className='lower'>in</span> Bridge Lending <span className='lower'>with</span> Automated Processes
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box >
                        <img src={Banner} className='w100' alt='Banner' />
                    </Box>


                    <Box className='whitebx'>
                        <Box>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Executive Summary
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                            A US-based financial firm specializing in bridge lending faced significant challenges with manual data entry and inefficient processes, resulting in lengthy turnaround times for loan origination. By automating their data tracking, data entry, and document management processes, Decimal Point Analytics enabled the firm to achieve a remarkable 60% reduction in processing time, enhancing operational efficiency and client satisfaction.
                            </Typography>
                        </Box>


                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                The Challenge
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                            The financial firm encountered multiple challenges in their bridge lending operations:
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Manual Data Entry:</strong> Time-consuming manual processes delayed updates on deposits and funding.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Quality Control Issues:  </strong> Difficulty conducting quality checks on over 3,000 active loans led to compliance concerns.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Inefficient Document Management:  </strong> Post-loan closing document handling was cumbersome and slow.
                                        </Typography>
                                    </li> 

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> High Turnaround Times:  </strong> The loan origination process had excessive turnaround times, affecting client satisfaction.
                                        </Typography>
                                    </li> 

                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3} className='mob_card'>
                            <Typography variant='h4' className='blue' gutterBottom>
                            Solution: Streamlined Bridge Lending Operations   
                            </Typography> 

                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center" my={3}>
                                        <img src={Infographics} className="topbanner" alt="Solution: Streamlined Bridge Lending Operations" />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box mt={1}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Results
                            </Typography> 

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>60% Reduction in Processing Time:  </strong> Streamlined operations reduced processing time from approximately 6 hours to 2.5 hours per deal using automated processes.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> 40% Decrease in Turnaround Times:  </strong> Improved loan tracking and borrower communication led to faster turnaround times.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Increased Data Accuracy:    </strong> Achieved a remarkable 99.7% accuracy rate, enhancing compliance and trust.
                                        </Typography>
                                    </li> 

                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Key Takeaways
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Operational Efficiency: </strong> Automated processes drastically reduced processing times by 60%.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Improved Compliance:  </strong> Comprehensive quality checks increased accuracy and compliance levels.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Enhanced Client Experience: </strong> Faster loan processing improved borrower satisfaction and engagement.
                                        </Typography>
                                    </li>
                                </ul> 

                                <Typography variant='h6' className='black jst'>
                                <Link to="/" className='link'>Decimal Point Analytics</Link> helped them streamline data tracking, entry, and document management processes.
                            </Typography>


                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                <Link to="/contact-us#contact_form" className="link">Contact us</Link> today to find out how we can help your organization achieve similar improvements!
                            </Typography>

                        </Box>

                    </Box>


                    <Casestudyback />

                </Container>
            </Box>


        </>
    );
}