import MRR from "../../asset/images/insight/article_thumbnails/implementing_machine_readable.webp";
import Oracle from "../../asset/images/insight/article_thumbnails/dpa_overcome_data.webp";
import Tokenization from "../../asset/images/insight/article_thumbnails/tokenization_of_asset.webp"
import INR_position from "../../asset/images/insight/article_thumbnails/regaining_inr_rightful.webp"
import Asset_Manager from "../../asset/images/insight/article_thumbnails/artificial_intelligence_heurastics.webp"
import PE_funds from "../../asset/images/insight/article_thumbnails/pe_funds_esg_challenge.webp"
import ESG_norms from "../../asset/images/insight/article_thumbnails/challenges_in_operationalizing.webp";
import ESG from "../../asset/images/insight/article_thumbnails/solving_challenges_esg.webp"
import Data_Vis from "../../asset/images/insight/article_thumbnails/data_vishualization.webp";
import NLP from "../../asset/images/insight/article_thumbnails/leverating_nlp.webp"
import Private_equity from "../../asset/images/insight/article_thumbnails/private_equity.webp"
import Roadmap_to_insti from "../../asset/images/insight/article_thumbnails/roadmap_of_institutional.webp"
import Big_Data from "../../asset/images/insight/article_thumbnails/way_forward_for_asset.webp"
import Invst_research from "../../asset/images/insight/article_thumbnails/disrupting_investment.webp";
import Asset_backed_sec from "../../asset/images/insight/article_thumbnails/data_analytics_asset_backed.webp";
import Role_of_adv_analytics from "../../asset/images/insight/article_thumbnails/redefining_retail_banking.webp"
import Interest_burden from "../../asset/images/insight/article_thumbnails/real_interest_rate_burden.webp"
import New_age_asset from "../../asset/images/insight/article_thumbnails/new_age_asset_managers.webp"
import Ind_on_currency from "../../asset/images/insight/article_thumbnails/india_on_currency.webp"
import Rising_CRUD_prices from "../../asset/images/insight/article_thumbnails/rising_crud_prices.webp"
import Rethinking_corpo from "../../asset/images/insight/article_thumbnails/rethinking_corporate_governance.webp"
import ROBO from "../../asset/images/insight/article_thumbnails/will_robo_advisors.webp"
import Ind_Research from "../../asset/images/insight/article_thumbnails/independent_research.webp"
import India_budget from "../../asset/images/insight/article_thumbnails/india_budget.webp"
import Donald_trump from "../../asset/images/insight/article_thumbnails/donald_trumph.webp";
import Germany_election from "../../asset/images/insight/article_thumbnails/germany_upcoming-election.webp";
import FORK from "../../asset/images/insight/article_thumbnails/fork_wars.webp";
import Alpha from "../../asset/images/insight/article_thumbnails/pursuit_of_alpha.webp"
import Research_redux from "../../asset/images/insight/article_thumbnails/research_redux.webp"
import MFID from "../../asset/images/insight/article_thumbnails/mifid_ii.webp"
import JPMorgan from "../../asset/images/insight/article_thumbnails/jp-morgan-index.webp";
import OpenAI from "../../asset/images/insight/article_thumbnails/openai.webp";
import Esg_requiem from "../../asset/images/insight/article_thumbnails/esg_requiem.webp";
import DigitalAsset from "../../asset/images/insight/article_thumbnails/digital_assets_2024.webp";
import Non_traditional_ways from "../../asset/images/insight/article_thumbnails/exploring_non_traditional.webp";
import HolographicFuture from "../../asset/images/insight/article_thumbnails/holographic_future.webp";
import india_modern_arthashastra from "../../asset/images/insight/article_thumbnails/india_modern_arthashastra.webp";



const Articles_Data = [
    {
        link: "/articles/india-modern-day-arthashastra-a-ministry-of-economic-security",
        imgsrc: india_modern_arthashastra,
        heading: "India’s Modern Day Arthashastra - A Ministry Of Economic Security"
    },
    {
        link: "/articles/the-holographic-future-of-regulation-why-we-need-a-radical-new-approach-unravelling-the-regulatory-knot",
        imgsrc: HolographicFuture,
        heading: "The Holographic Future of Regulation: Why We Need a Radical New Approach Unravelling the Regulatory Knot"
    },
    {
        link: "/articles/digital-assets-can-look-forward-to-a-brighter-future-in-2024",
        imgsrc: DigitalAsset,
        heading: "Digital Assets can look forward to a brighter future in 2024"
    },
    {
        link: "/articles/is-it-time-to-write-esg-requiem-not-quite",
        imgsrc: Esg_requiem,
        heading: "Is it time to write ESG’s Requiem? Not quite"
    },
    {
        link: "/articles/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot",
        imgsrc: OpenAI,
        heading: <span>LLMs, The Drama Around OpenAI:<br />Is Q* The Death* of <span style={{ textTransform: 'none' }}>Co-pilot?</span></span>
    },
    {
        link: "/articles/inclusion-indian-sovereign-bonds-jpmorgan-index",
        imgsrc: JPMorgan,
        heading: "Is the inclusion of Indian Sovereign Bonds in the JP Morgan Index a positive move?"
    },
    {
        link: "/articles/implementing-machine-readable-regulation-for-competitive-advantage",
        imgsrc: MRR,
        heading: "Implementing Machine Readable Regulation for Competitive Advantage"
    },
    {
        link: "https://www.oracle.com/customers/dpa-case-study/",
        imgsrc: Oracle,
        heading: "DPA overcomes project complexity, improves performance with Autonomous Database",
        linking: "external"
    },
    {
        link: "/articles/tokenization-of-assets-a-new-paradigm-for-businesses",
        imgsrc: Tokenization,
        heading: "Tokenization of assets a new paradigm for businesses"
    },
    {
        link: "/articles/regaining-inr-rightful-position-in-regional-trade",
        imgsrc: INR_position,
        heading: "Regaining INR's Rightful Position in Regional Trade"
    },
    {
        link: "/articles/how-can-asset-managers-engage-with-businesses-to-accelerate-drive-towards-net-zero",
        imgsrc: Asset_Manager,
        heading: "How can Asset managers engage with businesses to accelerate drive towards Net Zero"
    },
    {
        link: "/articles/pe-funds-and-esg-challenges",
        imgsrc: PE_funds,
        heading: "PE Funds and the ESG challenge"
    },
    {
        link: "/articles/challenges-in-operationalizing-esg-norms-in-asset-management",
        imgsrc: ESG_norms,
        heading: "Challenges in operationalizing ESG norms in Asset Management"
    },
    {
        link: "/articles/solving-challenges-in-esg-investing",
        imgsrc: ESG,
        heading: "Solving Challenges in ESG Investing"
    },
    {
        link: "/articles/data-visualization-and-the-art-of-effective-storytelling",
        imgsrc: Data_Vis,
        heading: "Data Visualization and the Art of Effective Storytelling"
    },
    {
        link: "/articles/how-asset-managers-are-leveraging-natural-language-processing-techniques",
        imgsrc: NLP,
        heading: "Leveraging NLP Techniques"
    },
    {
        link: "/articles/private-equity-adopting-a-data-first-approach",
        imgsrc: Private_equity,
        heading: "Private Equity - Adopting a Data First Approach"
    },
    {
        link: "/articles/adopting-changes-in-investment-research-methodologies-roadmap-to-institutional-transformation",
        imgsrc: Roadmap_to_insti,
        heading: "Roadmap to Institutional Transformation"
    },
    {
        link: "/articles/big-data-and-analytics-the-way-forward-for-asset-managers",
        imgsrc: Big_Data,
        heading: "Way forward for Asset Managers!"
    },
    {
        link: "/articles/disrupting-investment-research-process",
        imgsrc: Invst_research,
        heading: "Disrupting Investment Research"
    },
    {
        link: "/articles/data-analytics-in-asset-backed-securities",
        imgsrc: Asset_backed_sec,
        heading: "Data Analytics in Asset-Backed Securities (ABS)"
    },
    {
        link: "/articles/role-of-advanced-analytics-in-redefining-retail-banking",
        imgsrc: Role_of_adv_analytics,
        heading: "Role of Advanced Analytics in Redefining Retail Banking"
    },
    {
        link: "/articles/real-interest-rates-burder-on-indian-businesses",
        imgsrc: Interest_burden,
        heading: "Real Interest Rates Burden On Indian Businesses"
    },
    {
        link: "/articles/evolution-of-the-new-age-avatars-of-asset-managers",
        imgsrc: New_age_asset,
        heading: "New Age Asset Managers"
    },
    {
        link: "/articles/how-can-india-benefit-from-being-on-the-currency-watchlist-of-usa",
        imgsrc: Ind_on_currency,
        heading: "India on currency watchlist"
    },
    {
        link: '/articles/non-traditional-to-access-credit-risk',
        imgsrc: Non_traditional_ways,
        heading: "Exploring Non-traditional Ways to Assess Company Credit Risk",
    },
    {
        link: "/articles/india-leverage",
        imgsrc: Rising_CRUD_prices,
        heading: "India Leverage Rising Crude Prices"
    },
    {
        link: "/articles/rethinking-corporate-governance",
        imgsrc: Rethinking_corpo,
        heading: "Rethinking Corporate Governance"
    },
    {
        link: "/articles/robo-advisors",
        imgsrc: ROBO,
        heading: "Will Robo-advisors disrupt the dynamics in the financial services industry"
    },
    {
        link: "/articles/independant-research",
        imgsrc: Ind_Research,
        heading: "Independent Research"
    },
    {
        link: "/articles/indian-budget-17-18",
        imgsrc: India_budget,
        heading: "Indian Budget 2017-18"
    },
    {
        link: "/articles/trumps-presidency",
        imgsrc: Donald_trump,
        heading: "Donald Trump'S Presidency"
    },
    {
        link: "/articles/germany-and-upcoming-elections",
        imgsrc: Germany_election,
        heading: "Germany & The Upcoming Elections"
    },
    {
        link: "/articles/fork-wars",
        imgsrc: FORK,
        heading: "Fork Wars A Tale of two cryptocurrencies"
    },
    {
        link: "/articles/pursuit-of-alpha",
        imgsrc: Alpha,
        heading: "In Pursuit of Alpha"
    },
    {
        link: "/articles/research-redux",
        imgsrc: Research_redux,
        heading: "Research Redux"
    },
    {
        link: "/articles/assessing-quality-of-research",
        imgsrc: MFID,
        heading: "MIFID II Assessing Quality of Research!"
    }
];

export default Articles_Data;