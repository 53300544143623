import img1 from "../../asset/images/services/sba_blogs/blog_thumbnails/loan_process.webp";
import img2 from "../../asset/images/services/sba_blogs/blog_thumbnails/expert_sba.webp";
import img3 from "../../asset/images/services/sba_blogs/blog_thumbnails/what_are_small.webp";
import img4 from "../../asset/images/services/sba_blogs/blog_thumbnails/business_loan.webp";
import img5 from "../../asset/images/services/sba_blogs/blog_thumbnails/building_process_efficiency.webp";


import ApplySBALoan from "./SmallBusinessAdministration/ApplySBALoan";
import ExpertSBALenderService from "./SmallBusinessAdministration/ExpertSBALenderService";
import WhatAreSBALoans from "./SmallBusinessAdministration/WhatAreSBALoans";
import OverviewUSSmallBusinessLoans from "./SmallBusinessAdministration/OverviewUSSmallBusinessLoans";
import BuildingProcessEfficiency from "./SmallBusinessAdministration/BuildingProcessEfficiency";

const SBA_Data = [
    {
      link: function () {
        return (
          '/small-business-administration/' + this.heading.toLowerCase().trim().replace(/[:,()]/g, '').replace(/\s+/g, '-')
        );
      },
      imgsrc: img1,
      heading: "Step-by-step guide on how to apply for an SBA loan",
      content: ApplySBALoan
    },
    {
      link1: '/small-business-administration/sba-lender-service',
      imgsrc: img2,
      heading: "Expert SBA Lender Service Provider (LSP) Servicing with DPA",
      content: ExpertSBALenderService
    },
    {
      link: function () {
        return (
          '/small-business-administration/' + this.heading.toLowerCase().trim().replace(/[:,()?]/g, '').replace(/\s+/g, '-')
        );
      },
      imgsrc: img3,
      heading: "What are Small Business Administration (SBA) Loans?",
      content: WhatAreSBALoans
    },
    {
      link: function () {
        return (
          '/small-business-administration/' + this.heading.toLowerCase().trim().replace(/[:,()?]/g, '').replace(/\s+/g, '-')
        );
      },
      imgsrc: img4,
      heading: "Overview of US Small Business Loans",
      content: OverviewUSSmallBusinessLoans
    },
    {
      link: function () {
        return (
          '/small-business-administration/' + this.heading.toLowerCase().trim().replace(/[:,()?]/g, '').replace(/\s+/g, '-')
        );
      },
      imgsrc: img5,
      heading: "Building process efficiency for US Lenders to offer ERC/SBA loans",
      content: BuildingProcessEfficiency
    },
  ];
  

export default SBA_Data;