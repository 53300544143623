import React, { useEffect, } from "react";
import { AppBar, Container, Grid, Tabs, Tab, Typography, Box, TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import Aos from "aos";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom/cjs/react-router-dom";
import "aos/dist/aos.css";
import whyCP from "../../../asset/images/product/creditpulse/why_credit_pulse.webp"
import Infographics from "../../../asset/images/services/investor_relations/role_of_ddq.webp"
import service1 from "../../../asset/images/services/investor_relations/ddq_services/service1.png";
import service2 from "../../../asset/images/services/investor_relations/ddq_services/service2.png";
import service3 from "../../../asset/images/services/investor_relations/ddq_services/service3.png";
import service4 from "../../../asset/images/services/investor_relations/ddq_services/service4.png";
import service5 from "../../../asset/images/services/investor_relations/ddq_services/service5.png";
import { Autocomplete, ListItemButton } from "@mui/material";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box mt={5}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
}

const options = [
    { label: 'Investor Relations Solutions', to: '/investor-relations-solutions' },
    { label: 'RFP Management Solutions', to: '/request-for-proposals-solutions' },
    { label: 'Due Diligence Questionnaires', to: '/due-diligence-questionnaires-solutions' },
    { label: 'Consultant Database Management', to: '/consultant-database-management-solutions' },
    { label: 'Customer Relationship Management', to: '/customer-relationship-management-solutions' },
];

export default function DDQ() {
    const [value, setValue] = React.useState(2);

    const handleChange = (event, newValue) => {
        window.scrollTo(0, 0);
        setValue(newValue);
    };

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    const [value1, setValue1] = React.useState(options[2]);

    return (
        <>
            <Helmet>
                <title>Effective DDQ Management Solutions for Asset Managers - DPA</title>
                <meta name="description" content="Explore DPA’s tailored Due Diligence Questionnaire management services designed for asset managers to enhance transparency, compliance, and investor trust." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/due-diligence-questionnaires-solutions" />
            </Helmet>

            <Box id="investor_relations_tabs" className="maintabbedsection stiky" mb={5}>
                <Container maxWidth="lg" className="bdr">
                    <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <AppBar position="static" className="tabbedbar">
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="none"
                                    textColor="primary"
                                    variant="fullWidth"
                                    scrollButtons="off"
                                    aria-label="fullWidth auto tabs example"
                                    centered
                                >
                                    <Tab label="Investor Relations Solutions" {...a11yProps(0)} component={Link} to="/investor-relations-solutions" />
                                    <Tab label="RFP Management Solutions" {...a11yProps(1)} component={Link} to="/request-for-proposals-solutions" />
                                    <Tab label="Due Diligence Questionnaires" {...a11yProps(2)} component={Link} to="/due-diligence-questionnaires-solutions" />
                                    <Tab label="Consultant Database Management" {...a11yProps(3)} component={Link} to="/consultant-database-management-solutions" />
                                    <Tab label="Customer Relationship Management" {...a11yProps(4)} component={Link} to="/customer-relationship-management-solutions" />
                                </Tabs>
                            </AppBar>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box id="investor_relations_tabs_mob" className="maintabbedsection stiky" mb={5}  >
                <Container maxWidth="lg" className="bdr">
                    <Box className="alltxfieldlg">
                        <Autocomplete
                            disablePortal
                            options={options}
                            value={value1}
                            onChange={(event, newValue1) => setValue1(newValue1)}
                            fullWidth
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => <TextField {...params} />}
                            renderOption={(props, option) => (
                                <Link to={option.to}>
                                    <ListItemButton {...props}>
                                        <Typography variant="h6" className="mb0 black fw5">{option.label}</Typography>
                                    </ListItemButton>
                                </Link>
                            )}
                        />
                    </Box>
                </Container>
            </Box>

            <TabPanel value={value} index={2}>
                <Box id="investor_relations">
                    <Box className='section sec_bg' component='section' mt={'60px'}>
                        <Container maxWidth='lg' className='bdr'>
                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center synopsic_bx">
                                        <Box className='al_center'>
                                            <Typography variant='h1' className='black'>
                                                Due Diligence Questionnaires (DDQ) Management
                                            </Typography>
                                        </Box>
                                        <Typography variant='h6' className='black al_center' gutterBottom>
                                            Strengthen investor relationships with accurate, efficient, and transparent DDQ management solutions.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>

                    <Box className="section2" component="section">
                        <Container maxWidth="lg" className="bdr">

                            <Box mb={2}>
                                <Typography variant="h2" className="black al_left fw6 al_center" gutterBottom>
                                    Comprehensive DDQ Solutions <span className='lower'>for</span> Transparent <span className='lower'>and</span> Consistent Communication
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant='h6' className='black jst'>
                                    <strong>Due Diligence Questionnaires (DDQs)</strong> are integral to the investor onboarding and retention process, providing investors with insights into a fund manager’s operational practices, risk management, and regulatory compliance. Managing DDQs requires thorough, accurate, and timely responses, often tailored to meet each investor’s specific requirements. <strong>Decimal Point Analytics (DPA) </strong> offers a robust <strong>DDQ solution</strong> that enables fund managers to maintain transparency, strengthen investor relationships, and ensure compliance.
                                </Typography>
                            </Box>

                        </Container>
                    </Box>

                    <Box className="section2 sec_bg" component="section">
                        <Container maxWidth="lg" className="bdr">

                            <Box mb={2}>
                                <Typography variant="h2" className="black al_center fw6" gutterBottom>
                                    The Role <span className='lower'>of</span> DDQs <span className='lower'>in</span> Investor Screening
                                </Typography>
                            </Box>

                            <Typography variant='h6' className='black al_center'>
                                DDQs are a key part of the initial and ongoing due diligence process, helping investors assess:
                            </Typography>

                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center" my={4}>
                                        <img src={Infographics} className="w100" alt="The Role of DDQs in Investor Screening" />
                                    </Box>
                                </Grid>
                            </Grid>

                            <Typography variant='h6' className='black al_center'>
                                By providing consistent and accurate responses, fund managers can build trust and foster lasting relationships with investors, both new and existing.
                            </Typography>

                        </Container>
                    </Box>

                    <Box className="section2 mob_card" component="section">
                        <Container maxWidth='lg' className='bdr'>
                            <Box mb={2}>
                                <Typography variant="h2" className="black fw6 al_center" gutterBottom>
                                    DPA’s Comprehensive DDQ Services
                                </Typography>
                            </Box>

                            <Typography variant='h6' className='black al_center'>
                                DPA offers an end-to-end <strong>DDQ solution</strong> that simplifies and standardizes the response process, providing fund managers with a seamless way to meet investor expectations. Our DDQ services include:
                            </Typography>

                            <Grid container spacing={3} alignItems="center" justifyContent='center'>
                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className='boxbg mnhg3'>
                                        <Box className='al_center'>
                                            <img src={service1} className='allic' alt='Historical Data Management' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h6' className='blue al_center fw6'>
                                                Historical Data Management
                                            </Typography>
                                            <Typography variant='h6' className='black al_center mb0'>
                                                We build and maintain a centralized content library for <strong>DDQs</strong>, making it easy to retrieve and update past responses.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className='boxbg mnhg3'>
                                        <Box className='al_center'>
                                            <img src={service2} className='allic' alt='Tailored Drafts & Customization' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h6' className='blue al_center fw6'>
                                                Tailored Drafts & Customization
                                            </Typography>
                                            <Typography variant='h6' className='black al_center mb0'>
                                                Our team customizes each <strong>DDQ response</strong> to match the investor’s requirements, emphasizing critical information on compliance, risk, and governance.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className='boxbg mnhg3'>
                                        <Box className='al_center'>
                                            <img src={service3} className='allic' alt=' Timely Data Updates' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h6' className='blue al_center fw6'>
                                                Timely Data Updates
                                            </Typography>
                                            <Typography variant='h6' className='black al_center mb0'>
                                                DPA ensures responses are up-to-date with the latest data on financials, regulatory changes, and organizational updates.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className='boxbg mnhg3'>
                                        <Box className='al_center'>
                                            <img src={service4} className='allic' alt='Automation & Accuracy' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h6' className='blue al_center fw6'>
                                                Automation & Accuracy
                                            </Typography>
                                            <Typography variant='h6' className='black al_center mb0'>
                                                Leveraging automation tools and best practices, we streamline the response process, minimizing errors and ensuring consistent quality.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className='boxbg mnhg3'>
                                        <Box className='al_center'>
                                            <img src={service5} className='allic' alt='Compliance Management' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h6' className='blue al_center fw6'>
                                                Compliance Management
                                            </Typography>
                                            <Typography variant='h6' className='black al_center mb0'>
                                                Our solutions include robust checks for compliance, aligning responses with regulatory and governance standards relevant to investor due diligence.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>

                    <Box className="section2 sec_bg" component="section">
                        <Container maxWidth="lg" className="bdr">

                            <Box mb={2}>
                                <Typography variant="h2" className="black al_center fw6" gutterBottom>
                                    Why Choose DPA <span className='lower'>for</span> DDQ Support?
                                </Typography>
                            </Box>

                            <Grid container spacing={0} alignItems="center" justifyContent='center'>

                                <Grid item lg={5} md={5} sm={6} xs={12}>
                                    <Box className='al_center'>
                                        <img src={whyCP} className='topbanner5' alt='Why Choose DPA for DDQ Support?' />
                                    </Box>
                                </Grid>

                                <Grid item lg={7} md={7} sm={6} xs={12}>
                                    <Box>
                                        <Typography className="black" variant="h6">
                                            <ul>
                                                <li>
                                                    <Typography variant="h6" className="black jst" gutterbottom>
                                                        <strong> Enhanced Accuracy & Transparency: </strong>  DPA’s services are designed to provide consistent, accurate, and transparent responses that support investor confidence.
                                                    </Typography>
                                                </li>

                                                <li>
                                                    <Typography variant="h6" className="black jst" gutterbottom>
                                                        <strong>  Efficient Process Management: </strong> Through automation and streamlined workflows, DPA reduces response times, ensuring investors receive timely and relevant information.
                                                    </Typography>
                                                </li>

                                                <li>
                                                    <Typography variant="h6" className="black jst" gutterbottom>
                                                        <strong> Tailored to Investor Needs: </strong>  We adjust responses to meet specific investor requirements, reinforcing the fund’s commitment to transparency and compliance.
                                                    </Typography>
                                                </li>

                                                <li>
                                                    <Typography variant="h6" className="black jst" gutterbottom>
                                                        <strong> Advanced Technology for Consistency: </strong> Our LLM-enabled drafting process enhances accuracy and reduces manual intervention, maintaining high-quality responses across all <strong>DDQs</strong>.
                                                    </Typography>
                                                </li>
                                            </ul>
                                        </Typography>
                                    </Box>
                                </Grid>



                            </Grid>
                        </Container>
                    </Box>

                    <Box className="section2" component="section">
                        <Container maxWidth='lg' className='bdr'>
                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center synopsic_bx">
                                        <Box>
                                            <Typography variant="h2" className="black al_center fw6" gutterBottom>
                                                Build Stronger Investor Relationships <span className='lower'>with</span> DPA’s DDQ Solutions
                                            </Typography>
                                        </Box>
                                        <Typography variant='h6' className='black jst' gutterBottom>
                                            DPA’s DDQ support services help fund managers maintain accurate, timely, and transparent communication with investors, enhancing trust and compliance. Let us simplify your DDQ management to ensure you consistently meet investor expectations. <Link to="/contact-us#contact_form" className="link">Contact us</Link> today!
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </Box>
            </TabPanel>

        </>
    );
}
